import { routeLinks } from 'config/routeLinks';

export const LOCAL_STORE_AUTH_KEY = 'auth_tokens';
export const LOCAL_STORE_REFRESH_KEY = 'refresh_token';

export const routes = {
  main: () => routeLinks.home,
  login: () => routeLinks.login,
  passwordReset: () => routeLinks.passwordReset,
  passwordResetConfirm: () => routeLinks.passwordResetConfirm,
  cart: () => routeLinks.cart,
  profile: () => routeLinks.profile,
  favorites: () => routeLinks.favorites,
  discount: () => routeLinks.discount,
  orders: () => routeLinks.orders,
  ordered: () => routeLinks.ordered,
  products: () => routeLinks.products,
  categories: () => routeLinks.categories,
  staff: () => routeLinks.staff,
  siteFeedback: () => routeLinks.siteFeedback,
  notFound: () => routeLinks.notFound,
};

export const ERROR_TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 1500,
  type: 'error',
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};
export const SUCCESS_TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};
