import stores from 'stores/createStores';
import { LOCAL_STORE_AUTH_KEY, LOCAL_STORE_REFRESH_KEY } from 'config';
import { signIn, logOut } from '../api/v1/auth';

export const loginUser = async (params) => {
  const { data } = await signIn(params);

  localStorage.setItem(LOCAL_STORE_AUTH_KEY, data.access);
  localStorage.setItem(LOCAL_STORE_REFRESH_KEY, data.refresh);

  stores.GlobalStore.updateUserAuthentication(true);
};

export const logoutUser = async () => {
  const res = await logOut({
    refresh: localStorage.getItem(LOCAL_STORE_REFRESH_KEY),
  });

  if (res.status === 200) {
    localStorage.removeItem(LOCAL_STORE_AUTH_KEY);
    localStorage.removeItem(LOCAL_STORE_REFRESH_KEY);
    stores.GlobalStore.updateUserAuthentication(false);
  }
};
