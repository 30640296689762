import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { forgotPassword } from 'services/api/v1/auth';

import styles from './styles.module.scss';

const PasswordResetForm = () => {
  const { register, handleSubmit } = useForm();
  const [result, setResult] = useState({});

  const handleRequest = async (params) => {
    try {
      const data = await forgotPassword(params);

      setResult({ status: data.status });
    } catch ({ response }) {
      const error = response.data.email[0] || 'Произошла ошибка!';

      setResult({ error, status: response.status });
    }
  };

  if (result?.status === 200) {
    return (
      <div className={`${styles.wrapper} ${styles.wrapperError}`}>
        <h1 className={styles.title}>
          Письмо с инструкциями по восстановлению пароля отправлено
        </h1>

        <p className={styles.subtitle}>
          Мы отправили вам инструкцию по установке нового пароля
          на указанный адрес электронной почты (если в нашей базе данных есть такой адрес).
          <br />
          Вы должны получить ее в ближайшее время.
          Если вы не получили письмо, пожалуйста, убедитесь,
          что вы ввели адрес с которым Вы зарегистрировались,
          и проверьте папку со спамом.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        Восстановление пароля
      </h1>

      <p className={styles.subtitle}>
        Забыли пароль?
        Введите свой адрес электронной почты ниже,
        и мы вышлем вам инструкцию, как установить новый пароль.
      </p>

      <div className={styles.card}>
        <form className={styles.form} onSubmit={handleSubmit(handleRequest)}>
          <label htmlFor="email">
            <p>Е-mail</p>
            <input
              {...register('email', { required: true })}
              type="email"
              placeholder="Адрес электронной почты"
            />
          </label>

          <div className={styles.error}>{result?.error}</div>

          <button type="submit">Отправить</button>
        </form>
      </div>
    </div>
  );
};

export default observer(PasswordResetForm);
