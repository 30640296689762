import { makeAutoObservable } from 'mobx'
import { cartlinesDelete, cartlinesList } from 'services/api/v1/cartlines'

export default class CartStore {
  isLoading = false
  isLoadingNextPage = false
  count = 0
  sum = 0
  volume = 0
  total_discount = 0
  cartData = {}
  selectedRows = []
  nextCartPage = null

  constructor() {
    makeAutoObservable(this)
  }

  getCart = async ({ params }) => {
    this.isLoading = true

    params.set('limit', 10)
    params.set('offset', 0)

    try {
      const { data } = await cartlinesList({ params })

      this.nextCartPage = data.next

      const newData = {
        ...data,
        lines: data?.results?.map((item) => ({
          ...item,
          selected: false,
          discount: parseInt(Number(item?.discount) * 100, 10),
          key: item.id,
        })),
      }

      this.cartData = newData

      this.setCartCount(data.count)

      this.setCartSum(data.total_sum)

      this.setCartVolume(data.total_volume)

      this.total_discount = data.total_discount

      this.selectedRows = []
    } catch (error) {
      console.log('Some error on loading cart items!')
    } finally {
      this.isLoading = false
    }
  }

  getNextPageItems = async () => {
    this.isLoadingNextPage = true

    try {
      if (this.cartData.next) {
        const { searchParams } = new URL(this.cartData.next)

        const params = { params: searchParams }

        const { data } = await cartlinesList(params)

        if (data?.results) {
          const addNextList = data.results.map((item) => ({
            ...item,
            selected: false,
            discount: parseInt(Number(item?.discount) * 100, 10),
            key: item.id,
          }))

          this.cartData.next = data.next

          this.cartData.lines.push(...addNextList)
        }
      }
    } catch (error) {
      console.log('Some error on loading cart items!')
    } finally {
      this.isLoadingNextPage = false
    }
  }

  handleRemove = async (record) => {
    try {
      const { data } = await cartlinesDelete({ id: record.id })

      this.cartData.lines = this.cartData?.lines.filter(
        (item) => item.id !== record.id,
      )

      this.setCartSum(data.total_sum)

      this.setCartVolume(data.total_volume)

      this.decrementCount()
    } catch (error) {
      console.log(error)
    }
  }

  handleRemoveSelected = async () => {
    const listIds = this.selectedRows.map((row) => row.id) || []
    const results = await Promise.all(
      listIds.map((id) => cartlinesDelete({ id })),
    )

    const lastResponse = results[results.length - 1]

    if (lastResponse) {
      this.setCartSum(lastResponse.data.total_sum)
      this.setCartVolume(lastResponse.data.total_volume)
    }

    this.decrementCount(listIds.length)

    this.cartData.lines = this.cartData?.lines.filter(
      (item) => !listIds.includes(item.id),
    )
  }

  handleSelect = (record) => {
    const newValue = () => {
      const index = this.selectedRows.findIndex((row) => row.id === record.id)

      return index === -1
        ? [...this.selectedRows, record]
        : this.selectedRows.filter((row) => row.id !== record.id)
    }

    this.selectedRows = newValue()

    this.updateSelected()
  }

  handleSelectAll = (checked) => {
    this.selectedRows = checked ? this.cartData.lines : []

    this.updateSelected()
  }

  updateSelected = () => {
    this.cartData.lines = this.cartData.lines?.map((record) => {
      const isSelected = this.selectedRows.some((row) => row.id === record.id)

      return { ...record, key: record.id, selected: isSelected }
    })
  }

  updateProductValue = (data) => {
    const {
      amount,
      id,
      quantity,
      volume,
      total_sum,
      total_volume,
      total_discount,
      comment,
    } = data

    const updateValues = this.cartData.lines.map((item) => {
      if (item.id === id) {
        return { ...item, quantity, amount, volume, comment }
      }

      return item
    })

    this.setCartSum(total_sum || this.sum)

    this.setCartVolume(total_volume || this.volume)

    this.cartData.lines = updateValues

    this.total_discount = Number(total_discount)
  }

  setCartCount = (val) => {
    this.count = val
  }

  setCartSum = (val) => {
    this.sum = val
  }

  setCartVolume = (val) => {
    this.volume = val
  }

  updateCartCount = (number) => {
    this.count += number
  }

  incrementCount = () => {
    this.count += 1
  }

  decrementCount = (value = 1) => {
    this.count -= value
  }

  get isAllRowsSelected() {
    return this.cartData?.lines?.length === this.selectedRows?.length
  }
}
