import { useDeferredValue } from 'react';
import style from './style.module.scss';

const Loader = ({ isLoading = false, children, className = '' }) => {
  const deferIsLoading = useDeferredValue(isLoading);

  if (deferIsLoading) {
    return (
      <div className={`${style.loader} ${className}`}>
        <div />
        <div className={`${style.loaderLine} ${style.red}`} />
        <div className={`${style.loaderLine} ${style.yellow}`} />
        <div className={`${style.loaderLine} ${style.blue}`} />
        <div className={`${style.loaderLine} ${style.yellow}`} />
        <div className={`${style.loaderLine} ${style.blue}`} />
        <div className={`${style.loaderLine} ${style.yellow}`} />
        <div className={`${style.loaderLine} ${style.red}`} />
      </div>
    );
  }

  return children;
};

export default Loader;
