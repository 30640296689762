import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { observer, useLocalStore } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'config';
import useStore from 'hooks/useStore';
import Loader from 'components/shared/Loader';
import { ReactComponent as EyeSlash } from 'assets/svg/eye.svg';
import { ReactComponent as Eye } from 'assets/svg/eye-open.svg';
import Store from './Store';

import styles from './styles.module.scss';

const Login = () => {
  const { isUserAuthenticated } = useStore('GlobalStore');
  const { loginUser, error, isLoading } = useLocalStore(() => new Store());

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate(routes.main());
    }
  }, [isUserAuthenticated, navigate]);

  return (
    <Loader isLoading={isLoading}>
      <div className={styles.card}>
        <h1 className={styles.title}>Авторизация</h1>

        <form className={styles.form} onSubmit={handleSubmit(loginUser)}>
          <label htmlFor="login">
            <p>Логин</p>

            <input
              {...register('username', { required: true })}
              id="login"
              type="text"
              placeholder="Введите логин"
            />
          </label>

          <label htmlFor="password">
            <p>Пароль</p>

            <div className={styles.passwordInputWrapper}>
              <input
                {...register('password', { required: true })}
                type={showPassword ? 'text' : 'password'}
                value={password}
                id="password"
                placeholder="Пароль"
                onChange={(e) => {
                  setValue('password', e.target.value);
                  setPassword(e.target.value)
                }}
              />
              <button className={styles.btnShowPassword} type="button" onClick={togglePasswordVisibility}>
                {showPassword ? <Eye /> : <EyeSlash />}
              </button>
            </div>
          </label>

          <div className={styles.error}>{error}</div>

          <div className={styles.formBtnBox}>
            <button type="submit">Войти</button>

            <Link
              className={styles.forgotPassword}
              target="_blank"
              to={routes.passwordReset()}
            >
              Забыли пароль?
            </Link>

            <Link
              className={styles.btnAuthAsStaff}
              target="_blank"
              to={routes.staff()}
            >
              Вход для менеджеров
            </Link>
          </div>
        </form>
      </div>
    </Loader>
  );
};

export default observer(Login);
