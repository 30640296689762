import instance from 'services/request';

export const cartlinesList = (params) => instance.get('/v1/cartlines/', params);

export const cartlinesCreate = (params) => instance.post('/v1/cartlines/', params);

export const cartlinesRead = (params) => instance.get('/v1/cartlines/{id}/', params);

export const cartlinesUpdate = ({ id }) => instance.put(`/v1/cartlines/${id}/`);

export const cartlinesPartialUpdate = (id, data) => instance.patch(`/v1/cartlines/${id}/`, data);

export const cartlinesDelete = ({ id }) => instance.delete(`/v1/cartlines/${id}/`);
