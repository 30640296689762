import React, { useCallback, useState } from 'react'
import Button from 'components/shared/Button'
import { ReactComponent as Arrow } from 'assets/svg/arrow-filter-menu.svg'

import styles from './styles.module.scss'

const Dropdown = (props) => {
  const { defaultLabel, options = [], callback, toRight = false } = props
  const [isOpen, setIsOpen] = useState(false)

  const [selectedValue, setSelectedValue] = useState(null)

  const styleMenuPosition = toRight ? { right: 0 } : { left: 0 }

  const handleSelect = useCallback(
    (select) => {
      setSelectedValue(select)
      callback(select)
      setIsOpen((val) => !val)
    },
    [callback],
  )

  const handleOpen = () => setIsOpen((val) => !val)

  return (
    <div className={styles.dropdown}>
      <Button type="button" colorSchema="transparent" onClick={handleOpen}>
        {selectedValue?.label || defaultLabel}

        <Arrow className={`${styles.arrow} ${isOpen ? styles.open : ''}`} />
      </Button>

      {isOpen && (
        <>
          <ul className={styles.menu} style={styleMenuPosition}>
            {options?.map((el) => (
              <li className={styles.menuItem} key={el.label}>
                <button type="button" onClick={() => handleSelect(el)}>
                  {el?.label}
                </button>
              </li>
            ))}
          </ul>

          <div
            role="button"
            tabIndex={0}
            onKeyDown={null}
            label="dropdown"
            className={styles.overlay}
            onClick={() => setIsOpen((val) => !val)}
          />
        </>
      )}
    </div>
  )
}

export default Dropdown
