import { createPortal } from 'react-dom';
import { ReactComponent as Cancel } from 'assets/svg/cancel.svg';
import styles from './styles.module.scss';

const Modal = ({
  isOpen,
  setIsOpen,
  size,
  children,
  hideCloser,
  style,
}) => (
  isOpen
    && createPortal(
      <>
        <div className={`${styles.modal} ${styles[size]}`} style={style}>
          {
            !hideCloser && (
              <div className={styles.header}>
                <button className={styles.closer} onClick={() => setIsOpen(false)} type="button">
                  <Cancel />
                </button>
              </div>
            )
          }

          {children}
        </div>

        <div
          role="button"
          tabIndex={0}
          label="text"
          onKeyDown={() => {}}
          onClick={() => setIsOpen(false)}
          className={styles.overlay}
        />
      </>,
      document.body,
    )
);

export default Modal;
