import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useStore from 'hooks/useStore';
import Image from 'components/shared/Image';
import { ReactComponent as ZoomIn } from 'assets/svg/zoom-in.svg';
import { useBodyLock } from 'hooks/useBodyLock';
import ModalDescription from 'modules/ProductCard/ModalDescription';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

const InfoColumn = ({
  className,
  productId,
  preview,
  title,
  link,
  article,
  category: linkText,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  useBodyLock(isOpenModal);

  const { requestProduct, data, isLoading } = useStore('ProductStore');

  useEffect(() => {
    if (productId && isOpenModal) {
      requestProduct({ id: productId });
    }
  }, [productId, requestProduct, isOpenModal]);

  const { dictionaries } = useStore('GlobalStore');

  const getSlugById = useMemo(() => {
    const [, , catalogID, , categoryID] = link.split('/');

    const catalog = dictionaries?.results?.find(
      (item) => item.id === Number(catalogID),
    );
    const category = catalog?.categories?.find(
      (item) => item.id === Number(categoryID),
    );

    const catalogSlug = catalog?.slug || '';
    const categorySlug = category?.slug || '';

    return `/products/${catalogSlug}/${categorySlug}/`;
  }, [dictionaries, link]);

  return (
    <div className={styles.container}>
      <ModalDescription
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        images={data?.images}
        params={data?.params}
        videoPath={data?.youtube}
        title={data?.title}
        article={data?.article_number}
        isLoading={isLoading}
      />
      <button
        type="button"
        className={styles.btnZoomIn}
        onClick={() => setIsOpenModal((val) => !val)}
      >
        <Image className={className} src={preview} alt="cart item image" />

        <span className={styles.zoom}>
          <ZoomIn />
        </span>
      </button>
      <div className={styles.itemInfo}>
        <button
          type="button"
          className={styles.title}
          onClick={() => setIsOpenModal((val) => !val)}
        >
          {title}
        </button>

        <p className={styles.link}>
          <Link to={getSlugById || link}>{linkText}</Link>
        </p>

        <p className={styles.article}>
          Артикул:
          <span>{article}</span>
        </p>
      </div>
    </div>
  );
};

export default observer(InfoColumn);
