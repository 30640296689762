import axios from 'axios';
import { LOCAL_STORE_AUTH_KEY } from 'config';

export const getNewAccessToken = async (refreshToken) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL_PREFIX}/v1/auth/token/refresh/`, {
      refresh: refreshToken,
    });

    const { access } = response.data;

    localStorage.setItem(LOCAL_STORE_AUTH_KEY, access);

    return access;
  } catch (error) {
    return null;
  }
};
