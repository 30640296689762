import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';

const Pagination = ({ isNextPageAvailable, isNextPageLoading, currentPage, handlePageClick, showMoreProducts, totalPages, isDesktop }) => (
  <div className={styles.paginationBlock}>
    {isNextPageAvailable
                && (
                <button
                  type="button"
                  disabled={isNextPageLoading}
                  onClick={showMoreProducts}
                  className={`${styles.pageControls} ${styles.showMoreBtn}`}
                >
                  Показать еще
                </button>
                )}

    <div className={styles.paginationWrapper}>
      {currentPage > 0 && (
      <button
        className={styles.pageControls}
        type="button"
        onClick={() => handlePageClick({ selected: 0 })}
      >
        В начало
      </button>
      )}
      <ReactPaginate
        nextLabel="Дальше"
        pageCount={totalPages}
        pageRangeDisplayed={isDesktop ? 5 : 2}
        marginPagesDisplayed={2}
        previousLabel=""
        renderOnZeroPageCount={null}
        containerClassName={styles.paginationList}
        pageLinkClassName={styles.pageItem}
        activeLinkClassName={styles.pageItemActive}
        nextClassName={styles.pageControls}
        disabledClassName={styles.disabledPageControl}
        onPageChange={handlePageClick}
        forcePage={currentPage}
      />
    </div>
  </div>
);

export default Pagination;
