import React from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { changePassword } from 'services/api/v1/users';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';

const ChangePasswordForm = () => {
  const {
    register, handleSubmit, setError, formState: { errors }, reset,
  } = useForm({
    defaultValues: {
      old_password: '',
      password: '',
      password2: '',
    },
  });

  const onSend = async (values) => {
    try {
      await changePassword(values);

      toast('Вы изменили свой пароль!', {
        position: 'top-right',
        autoClose: 1500,
        type: toast.TYPE.INFO,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });

      reset();
    } catch ({ response }) {
      const err = Object.entries(response.data);

      err.forEach(([key, val]) => {
        setError(key, {
          type: 'server',
          message: val[0] || 'Вы предоставили неверные данные',
        });
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSend)}>
        <Input
          label="Старый пароль"
          register={register}
          name="old_password"
          required
          type="password"
          isError={errors.old_password}
          errorMessage={errors.old_password?.message}
        />

        <Input
          label="Новый Пароль"
          register={register}
          name="password"
          required
          type="password"
          isError={errors.password}
          errorMessage={errors.password?.message}
        />

        <Input
          label="Подтвердите пароль"
          register={register}
          name="password2"
          required
          type="password"
          isError={errors.password2}
          errorMessage={errors.password2?.message}
        />

        <Button
          colorSchema="green"
          type="submit"
        >
          Сохранить
        </Button>
      </form>

      <ToastContainer />
    </>
  );
};

export default ChangePasswordForm;
