import { useEffect } from 'react'
import Table from 'rc-table'
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import { useInView } from 'react-intersection-observer'
import { downloadOrderDataByID } from 'helpers/downloadOrderDataByID'
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-round.svg'
import { ReactComponent as Repeat } from 'assets/svg/repeat.svg'
import { currentOrderColumns } from './config'
import styles from './styles.module.scss'

const CurrentOrderTable = ({ data, orderID, handleShowList, handleRepeatOrder }) => {
  const isEmpty = data?.results?.length > 0

  const { getNextPageOrderById } = useStore('UserStore')

  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      getNextPageOrderById(orderID)
    }
  }, [orderID, getNextPageOrderById, inView])

  return isEmpty ? (
    <>
      <div className={styles.observedItemBtnBox}>
        <button className={styles.btn} type="button" onClick={handleShowList}>
          <ArrowIcon />
          Вернуться к списку
        </button>

        <div className={styles.btnActionSWrapper}>
          <button
            className={styles.btnDownload}
            type="button"
            onClick={() => downloadOrderDataByID(orderID)}
          >
            Скачать XLSX
          </button>

          <button
            className={styles.btnRepeat}
            type="button"
            onClick={() => handleRepeatOrder({
              id: orderID,
              total_count: data?.count,
            })}
          >
            Повторить заказ
            <Repeat />
          </button>
        </div>
      </div>

      <Table
        className={styles.tableOrder}
        columns={currentOrderColumns()}
        data={data?.results}
        rowKey={() => window.self.crypto.randomUUID()}
      />

      <div ref={ref} />
    </>
  ) : (
    <div>Нет данных</div>
  )
}

export default observer(CurrentOrderTable)
