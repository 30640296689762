import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import PageNotFound from 'routes/PageNotFound';
import Container from 'components/shared/Container';
import Title from 'components/shared/Title';
import Card from 'components/Card';
import Loader from 'components/shared/Loader';

import styles from './styles.module.scss';

const Categories = () => {
  const { catalogSlug } = useParams();
  const { setCatalogSlug, categoriesListByCatalog, isLoadCriticalData } = useStore('GlobalStore');

  useEffect(() => {
    setCatalogSlug(catalogSlug);
  }, [catalogSlug, setCatalogSlug]);

  if (!isLoadCriticalData) {
    return (
      <div className={styles.loadingWrapper}>
        <Loader isLoading={!isLoadCriticalData} />
      </div>
    );
  }

  if (!categoriesListByCatalog) {
    return <PageNotFound />;
  }

  return (
    <Container>
      <Title>
        {categoriesListByCatalog?.title}
      </Title>

      <div className={styles.catalogList}>
        {
            categoriesListByCatalog?.categories?.map((item) => item.parent_category === null && (
              <Card
                key={item.id}
                image={item.image}
                preview={item.preview}
                name={item.title}
                catalogId={item.id}
                slug={item.slug}
                isSubCatalogCard
              />
            ))
          }
      </div>
    </Container>
  );
};

export default observer(Categories);
