import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as MobxProvider } from 'mobx-react';
import RootRouter from 'routes';
import Loader from 'components/shared/Loader';
import ErrorBoundary from 'components/ErrorBoundary';
import ScrollToTop from 'components/shared/ScrollToTop';
import CriticalDataLoader from 'modules/CriticalDataLoader';
import { ToastContainer } from 'react-toastify';
import * as stores from 'stores/createStores';

const App = () => (
  <ErrorBoundary>
    <MobxProvider {...stores}>
      <BrowserRouter>
        <CriticalDataLoader>
          <Suspense fallback={<Loader />}>
            <RootRouter />
            <ScrollToTop />
          </Suspense>
        </CriticalDataLoader>
        <ToastContainer />
      </BrowserRouter>
    </MobxProvider>
  </ErrorBoundary>
);

export default App;
