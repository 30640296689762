import SearchProductsContainer from 'modules/SearchProducts';
import ScrollToTop from 'components/ScrollToTop';

const SearchPage = () => (
  <ScrollToTop>
    <SearchProductsContainer />
  </ScrollToTop>
);

export default SearchPage;
