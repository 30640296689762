import LoginTemplate from 'components/templates/LoginTemplate';
import PasswordResetConfirm from 'modules/PasswordResetConfirm';

const ResetPasswordPage = () => (
  <LoginTemplate>
    <PasswordResetConfirm />
  </LoginTemplate>
);

export default ResetPasswordPage;
