import { useState } from 'react';
import Marker from 'components/Markers';
import { ReactComponent as ZoomIn } from 'assets/svg/zoom-in.svg';
import { useBodyLock } from 'hooks/useBodyLock';
import ModalDescription from './ModalDescription';
import ImagesList from './ImagesList';
import CardActions from './CardActions';
import BaseInfo from './BaseInfo';
import Like from './Like';
import styles from './styles.module.scss';
import PriceInfo from './PriceInfo';

const ProductCard = ({ cardView, data }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [hasInCart, setHasInCart] = useState(data.in_cart?.count > 0);
  const [cartItemId, setCartItemId] = useState(data.in_cart?.id);
  const [cartCount, setСartCount] = useState(
    data.quantity || data.in_cart?.count
      ? String(data.quantity || data.in_cart?.count)
      : String(String(data.min_quantity) || data.in_cart?.count),
  );
  useBodyLock(isOpenModal);

  return (
    <>
      <div className={`${styles.card} ${styles[cardView] || ''}`}>
        <div className={styles.header}>
          <div className={styles.label}>
            {data.new && <Marker.New />}
            {data.popular && <Marker.Popular />}
            {data.sale && <Marker.Sale />}

            <Like
              isSelected={data.liked}
              id={data.id}
              categoryUrl={data.category?.url}
            />
          </div>

          <button
            type="button"
            className={styles.btnZoomIn}
            onClick={() => setIsOpenModal((val) => !val)}
          >
            <ImagesList
              alt={data.title}
              images={data.images}
              article={data.article_number}
              cardView={cardView}
              id={data.id}
            />

            <span className={styles.zoom}>
              <ZoomIn />
            </span>
          </button>
        </div>

        <BaseInfo data={data} setIsOpen={setIsOpenModal} cardView={cardView} />
        <PriceInfo
          sale={data.sale}
          price={data.price}
          oldPrice={data.old_price}
        />
        <CardActions
          productData={data}
          hasInCart={hasInCart}
          setHasInCart={setHasInCart}
          cartItemId={cartItemId}
          setCartItemId={setCartItemId}
          cartCount={cartCount}
          setСartCount={setСartCount}
        />
      </div>

      <ModalDescription
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        images={data.images}
        title={data.title}
        article={data.article_number}
        params={data.params}
        videoPath={data.youtube}
        actions={(
          <>
            <PriceInfo
              sale={data.sale}
              price={data.price}
              oldPrice={data.old_price}
            />
            <CardActions
              productData={data}
              hasInCart={hasInCart}
              setHasInCart={setHasInCart}
              cartItemId={cartItemId}
              setCartItemId={setCartItemId}
              cartCount={cartCount}
              setСartCount={setСartCount}
            />
          </>
        )}
      />
    </>
  );
};

export default ProductCard;
