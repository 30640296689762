import { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import styles from './styles.module.scss'

const styleOnStickyHeader = {
  left: 0,
  right: 'initial',
}

const DiscountButton = ({ isSticky }) => {
  const { user, updateUser } = useStore('UserStore')
  const [isOpen, setIsOpen] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [seconds, setSeconds] = useState(30);

  const startTimer = () => {
    setButtonDisabled(true);

    const timerInterval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setTimeout(() => {
      setButtonDisabled(false);
      setSeconds(30);
      clearInterval(timerInterval);
    }, 30000);
  };

  const handlePrepayStatus = async () => {
    try {
      await updateUser({ prepay: !user.prepay })

      startTimer()
    } catch (error) {
      console.log("User don't updated");
    }
  }

  const handlePrepayPanel = () => setIsOpen((val) => !val)

  return (
    <div className={styles.discount}>
      <button
        className={`${styles.btn} ${styles.btnDiscount} ${user.prepay ? styles.btnDiscountPrepay : ''}`}
        type="button"
        onClick={handlePrepayPanel}
      >
        {user.prepay ? 'Предоплата' : 'Отсрочка'}

        <span>{user.prepay ? 'скидка' : 'Базовая цена'}</span>
      </button>

      {isOpen && (
        <div className={styles.acceptPrepare} style={isSticky ? styleOnStickyHeader : {}}>
          <div className={styles.acceptPrepareCurrent}>
            <span>Текущий вариант:</span>
            {' '}
            {user.prepay ? 'Предоплата' : 'Отсрочка'}
          </div>

          <div className={styles.acceptPrepareCurrent}>
            <span>Хотите изменить на:</span>
            {' '}
            {!user.prepay ? 'Предоплата' : 'Отсрочка'}
          </div>

          <button
            type="button"
            onClick={handlePrepayStatus}
            disabled={isButtonDisabled}
          >
            {isButtonDisabled ? seconds : 'Да'}
          </button>

          <button className={styles.btnClose} type="button" onClick={handlePrepayPanel}>
            Закрыть
          </button>
        </div>
      )}
    </div>
  )
}

export default observer(DiscountButton)
