import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { routeLinks } from 'config/routeLinks';
import { cartlinesCreate, cartlinesDelete } from 'services/api/v1/cartlines';
import ProductCardToast from 'components/ProductCardToast';
import Button from 'components/shared/Button';
import ProductCounter from 'components/ProductCounter';
import { ReactComponent as Cart } from 'assets/svg/cart.svg';
import { ReactComponent as Closer } from 'assets/svg/closer.svg';
import { AddPopconfirm } from 'components/ProductCounter/AddPopconfirm';
import useStore from 'hooks/useStore';
import styles from './styles.module.scss';

const CardActions = ({
  productData,
  hasInCart,
  setHasInCart,
  cartItemId,
  setCartItemId,
  cartCount,
  setСartCount,
}) => {
  const [isProductAdded, setIsProductAdded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { incrementCount, decrementCount, setCartSum } = useStore('CartStore');

  const handleAddToCart = useCallback(async () => {
    try {
      const { data } = await cartlinesCreate({
        product_id: productData.id,
        quantity: productData.in_cart?.count || productData.min_quantity || '1',
        comment: '',
      });

      incrementCount();
      setIsProductAdded(true);
      setCartItemId(data.id);
      setCartSum(data.total_sum);
      setHasInCart(true);
      setIsPopupOpen(false);
    } catch (error) {
      console.log('Problem with loading data');
    }
  }, [incrementCount, productData, setCartSum, setHasInCart, setCartItemId]);

  const handleRemoveOrder = useCallback(async () => {
    try {
      const { data } = await cartlinesDelete({ id: cartItemId });
      decrementCount();
      setCartSum(data.total_sum);
      setHasInCart(false);
    } catch (error) {
      console.log('Problem with loading data');
    }
  }, [cartItemId, decrementCount, setCartSum, setHasInCart]);

  const toasts = [
    isProductAdded && {
      type: 'info',
      message: 'Добавлено в корзину',
    },
    productData.in_cart?.count === productData.in_stock && {
      type: 'danger',
      message: 'Максимальное количество',
    },
  ].filter((item) => item !== false);

  const handleToCart = () => {
    if (productData.min_quantity > 1) {
      setIsPopupOpen(true);
    } else {
      handleAddToCart();
    }
  };

  const renderAddBtn = (
    <Button
      onClick={handleToCart}
      className={styles.addToCart}
      icon={<Cart width={24} height={21} />}
    >
      В корзину
    </Button>
  );

  const renderProductControls = (
    <div className={styles.basketCounter}>
      <ProductCounter
        id={cartItemId}
        inStock={productData.in_stock}
        productId={productData.id}
        minQuantity={String(productData.min_quantity)}
        value={cartCount}
        setValue={setСartCount}
      />

      <Link to={routeLinks.cart} className={styles.basketCounterCart}>
        <Cart />
      </Link>

      <button
        type="button"
        className={styles.basketCounterClose}
        onClick={handleRemoveOrder}
      >
        <Closer />
      </button>

      <ProductCardToast toasts={toasts} />
    </div>
  );

  return (
    <div className={styles.container}>
      {!hasInCart ? (
        <>
          {renderAddBtn}
          <AddPopconfirm
            count={productData.min_quantity}
            open={isPopupOpen}
            setOpen={setIsPopupOpen}
            action={handleAddToCart}
          />
        </>
      ) : (
        renderProductControls
      )}
    </div>
  );
};

export default observer(CardActions);
