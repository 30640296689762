import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useSearchParams } from 'react-router-dom'
import useStore from 'hooks/useStore'
import debounce from 'utils/debounce'
import FilterOrdering from 'modules/FilterOrdering'
import Container from 'components/shared/Container'
import Title from 'components/shared/Title'
import Loader from 'components/shared/Loader'
import Dropdown from 'components/shared/Dropdown'
import { excludeSearchParams } from 'helpers/excludeSearchParams'
import { ReactComponent as Search } from 'assets/svg/search.svg'

import Invoices from './Invoices'
import CartTable from './CartTable'
import { sortOptions } from './config'
import styles from './styles.module.scss'

const CartContainer = () => {
  const {
    getCart,
    isLoading,
    count,
    handleRemove,
    handleRemoveSelected,
    handleSelect,
    handleSelectAll,
    selectedRows,
    isAllRowsSelected,
    sum,
    volume,
    total_discount,
    nextCartPage,
  } = useStore('CartStore')
  const { user } = useStore('UserStore')
  const { getListFilterOptions, isLoadCriticalData } = useStore('GlobalStore')

  const [searchParams, setSearchParams] = useSearchParams()
  const { prepay, is_stuff } = user

  useEffect(() => {
    let ready = true

    if (ready && prepay !== undefined) {
      getCart({ params: searchParams })
    }

    return () => { ready = false }
  }, [getCart, searchParams, prepay])

  const onChangeSearchInput = debounce((event) => {
    setSearchParams([
      ...excludeSearchParams(searchParams, ['search']),
      ['search', event.target.value],
    ])
  }, 500)

  const handleCatalogFiltering = (param) => {
    const withOutParams = excludeSearchParams(searchParams, ['catalog'])

    if (param.value !== null) {
      setSearchParams([...withOutParams, ['catalog', param?.value]])
    }

    if (param.value === null) {
      setSearchParams(withOutParams)
    }
  }

  if (!isLoadCriticalData) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader isLoading={!isLoadCriticalData} />
      </div>
    )
  }

  return (
    <Container>
      <Title>
        Корзина
        <span className={styles.productTypeCounter}>
          {`${count} наименований товара`}
        </span>
      </Title>

      {count > 0 && nextCartPage !== null && (
        <Invoices
          totalVolume={volume}
          totalSum={sum}
          totalDiscount={total_discount}
          isPrepay={prepay}
          isStuff={is_stuff}
          isTopCartInfo
        />
      )}

      <FilterOrdering customOption={sortOptions} isCartFilter>
        <div className={styles.searchInput}>
          <input
            type="text"
            onChange={onChangeSearchInput}
            name="cart_search"
            placeholder="Найти"
          />

          <button type="button">
            <Search />
          </button>
        </div>

        <div className={styles.catalogBasketFilter}>
          <span>Каталог:</span>
          <Dropdown
            toRight
            options={getListFilterOptions}
            callback={handleCatalogFiltering}
            defaultLabel={getListFilterOptions?.[0]?.label}
          />
        </div>
      </FilterOrdering>

      <div className={styles.grid}>
        <Loader isLoading={isLoading} className={styles.loaderBox}>
          <CartTable
            handleRemove={handleRemove}
            handleRemoveSelected={handleRemoveSelected}
            handleSelect={handleSelect}
            handleSelectAll={handleSelectAll}
            selectedRows={selectedRows}
            isAllRowsSelected={isAllRowsSelected}
            styles={styles}
          />

          {count > 0 && (
            <Invoices
              totalVolume={volume}
              totalSum={sum}
              totalDiscount={total_discount}
              isPrepay={prepay}
              isStuff={is_stuff}
            />
          )}
        </Loader>
      </div>
    </Container>
  )
}

export default observer(CartContainer)
