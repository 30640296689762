import { useEffect, useState } from 'react'
import styles from './styles.module.scss'

const Toast = ({ item }) => {
  const config = {
    info: {
      styles: {
        background: 'var(--orange)',
        animation: 'move-up 2s linear forwards',
      },
    },

    danger: {
      styles: {
        background: 'var(--red-1)',
        animation: 'move-up2 2s linear forwards',
      },
    },
  }

  return (
    <div className={`${styles.toast} ${styles.active}`} style={config[item.type]?.styles || {}}>
      <div className={styles.content}>
        <span className={styles.message}>{item.message}</span>
      </div>
    </div>
  )
}

const ProductCardToast = ({ toasts }) => {
  const [currentToast, setCurrentToast] = useState(null)

  const delayToRenderToast = (item, index) => {
    const delay = 2000 * index;

    // eslint-disable-next-line consistent-return
    setTimeout(() => {
      if (item.isEmpty) {
        setCurrentToast(null)
        return null
      }

      setCurrentToast(<Toast item={item} />)
    }, delay)
  }

  useEffect(() => {
    toasts.push({ isEmpty: true })

    toasts.map(delayToRenderToast)
  }, [toasts])

  return (currentToast)
}

export default ProductCardToast
