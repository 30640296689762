import { useState } from 'react';
import Button from 'components/shared/Button';
import Avatar from './UserAvatar';

import EditProfile from './Forms/EditProfile';
import UserProfile from './UserProfile';

import styles from './styles.module.scss';

const User = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const onChangeMode = () => setIsEditMode((val) => !val);

  return (
    <div className={styles.userTile}>
      <Avatar isEditMode={isEditMode} />

      <div className={styles.userBody}>
        {isEditMode ? <EditProfile onEdit={setIsEditMode} /> : <UserProfile />}
      </div>

      <div className={`${styles.userBody} ${styles.editModeBtn}`}>
        <Button colorSchema={isEditMode ? 'red' : 'blue'} onClick={onChangeMode}>
          {isEditMode ? 'Отменить' : 'Редактировать' }
        </Button>
      </div>
    </div>
  );
};

export default User;
