import styles from './style.module.scss'

const ContactLink = ({ href, icon, text }) => (
  <a className={styles.contactLink} href={href}>
    <div className={styles.contactLinkIconWrapper}>
      {icon}
    </div>
    {text}
  </a>
)

export default ContactLink
