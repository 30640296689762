import { makeAutoObservable } from 'mobx';
import { LOCAL_STORE_AUTH_KEY, ERROR_TOAST_CONFIG } from 'config';
import { getDataMainPage } from 'services/api/v1/base';
import { catalogsList, catalogsCategoriesProductsList } from 'services/api/v1/catalogs';
import { toast } from 'react-toastify'
import axios from 'axios';

export default class GlobalStore {
  isUserAuthenticated = Boolean(localStorage.getItem(LOCAL_STORE_AUTH_KEY));
  isLoadCriticalData = false;

  isLoadingProducts = false;
  isLoadingBrands = false;

  isLoading = false;
  isNextPageLoading = false;
  isNextPageAvailable = true;
  products = {}
  offset = 0
  limit = 60
  currentSearchParams = new URLSearchParams();
  currentPage = 0;
  totalCount = 0;
  totalPages = 0;

  breakpoint = 'desktop';
  countLoadingPage = 0;
  requestData = {};
  catalogSlug = '';
  categorySlug = '';
  videosliders = [];

  // data product
  dictionaries = {};
  brands = {};
  categories = [];

  // data main page >
  banners = [];
  sliders = [];
  manager = {};
  menu = [];
  baseInfo = {};

  cancelTokenSource = null;

  constructor() {
    makeAutoObservable(this);
  }

  updateUserAuthentication = (value) => {
    this.isUserAuthenticated = value;
  };

  getBaseData = async () => {
    const { data } = await getDataMainPage();

    this.clientfirmID = data.clientfirm_id;
    this.banners = data.banners;
    this.sliders = data.sliders;
    this.manager = data.manager;
    this.menu = data.menu;
    this.baseInfo = data.user_info;
    this.videosliders = data.videosliders
  };

  getDictionaries = async () => {
    this.isLoading = true;
    const { data } = await catalogsList();

    this.dictionaries = data;
    this.isLoading = false;
  };

  setBreakpoint = (val) => {
    this.breakpoint = val;
  };

  setCatalogSlug = (catalogSlug) => {
    this.catalogSlug = catalogSlug;
  };

  setCategorySlug = (categorySlug) => {
    this.categorySlug = categorySlug;
  };

  get categoriesListByCatalog() {
    const data = this.dictionaries.results?.find(({ slug }) => (
      slug === this.catalogSlug
    ));

    return data;
  }

  get getListFilterOptions() {
    const dictOptions = this.dictionaries?.results?.map(({ id, title }) => ({
      value: id,
      label: title,
    }));

    return [{ label: 'Все каталоги', value: null }, ...dictOptions || []];
  }

  setIsLoadingCriticalData = (val) => {
    this.isLoadCriticalData = val;
  };

  get sideMenuTree() {
    const arr = this.categoriesListByCatalog?.categories || [];

    const idMap = new Map();

    arr.forEach((obj) => {
      idMap.set(obj.id, { ...obj, child: [] });
    });

    arr.forEach((obj) => {
      const parentID = obj.parent_category;

      if (parentID !== null) {
        const childByID = idMap.get(obj.id);

        idMap.get(parentID).child.push(childByID);
      }
    });

    return Array.from(idMap.values()).filter((obj) => obj.parent_category === null);
  }

  updateProducts = (products, append = false) => {
    if (products.next) {
      this.offset += this.limit;
      this.isNextPageAvailable = true;
    } else {
      this.isNextPageAvailable = false;
    }

    if (append) {
      this.products.results = [...this.products.results, ...products.results];
    } else {
      this.products = products;
    }
  };

  requestProducts = async (requestData) => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled due to new request.');
    }

    this.cancelTokenSource = axios.CancelToken.source();

    this.products = {}

    try {
      this.isLoading = true
      this.offset = requestData?.selected ? requestData.selected * this.limit : 0;

      const params = new URLSearchParams([...this.currentSearchParams, ['limit', this.limit], ['offset', this.offset]]);

      const { data } = await catalogsCategoriesProductsList({ ...requestData.params, params, cancelToken: this.cancelTokenSource.token })
      this.updateProducts(data);
      this.totalCount = data.count;
      this.totalPages = Math.ceil(data.count / this.limit);
      this.currentPage = requestData?.selected || 0;
      this.isLoading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG);
        this.isLoading = false;
      }
    }
  }

  requestNextPage = async (requestData) => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled due to new request.');
    }

    this.cancelTokenSource = axios.CancelToken.source();

    try {
      this.isNextPageLoading = true

      const params = new URLSearchParams([...this.currentSearchParams, ['limit', this.limit], ['offset', this.offset]]);

      const { data } = await catalogsCategoriesProductsList({ ...requestData.params, params, cancelToken: this.cancelTokenSource.token })
      this.updateProducts(data, true)
      this.currentPage += 1;
      this.isNextPageLoading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG);
        this.isNextPageLoading = false;
      }
    }
  }

  get categoryBrands() {
    const categories = this.categoriesListByCatalog?.categories;

    return categories?.filter(({ slug }) => slug === this.categorySlug)[0]?.brands;
  }

  get categoryFilters() {
    const categories = this.categoriesListByCatalog?.categories;

    return categories?.filter(({ slug }) => slug === this.categorySlug)[0]?.filters;
  }

  setCurrentSearchParams = (newSearchParams) => {
    if (newSearchParams) {
      this.currentSearchParams = newSearchParams;
    }
  }
}
