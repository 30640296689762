import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { routes } from 'config';
import { changePassword } from 'services/api/v1/auth';

import styles from './styles.module.scss';

const PasswordResetConfirm = () => {
  const { register, handleSubmit } = useForm();
  const [searchParams] = useSearchParams();
  const [result, setResult] = useState({});

  const handleRequest = async (params) => {
    const token = searchParams.get('token');

    try {
      const data = await changePassword({ ...params, token });

      setResult({ status: data.status });
    } catch ({ response }) {
      const [name, list] = Object.entries(response.data)[0];

      setResult({ errors: { name, list }, status: response.status });
    }
  };

  if (result?.status === 404 || result?.errors?.name === 'token') {
    return (
      <div className={`${styles.wrapper} ${styles.wrapperError}`}>
        <h1 className={styles.title}>Ошибка восстановления пароля</h1>

        <p className={styles.subtitle}>
          Неверная ссылка для восстановления пароля.
          <br />
          Возможно, ей уже воспользовались. Пожалуйста, попробуйте восстановить пароль еще раз.
        </p>

        <Link className={styles.linkToLogin} to={routes.passwordReset()}>Вернуться</Link>
      </div>
    );
  }

  if (result?.status === 200) {
    return (
      <div className={`${styles.wrapper} ${styles.wrapperError}`}>
        <h1 className={styles.title}>Восстановление пароля завершено!</h1>

        <p className={styles.subtitle}>
          Ваш пароль был сохранен. Теперь вы можете войти.
        </p>

        <Link className={styles.linkToLogin} to={routes.login()}>Войти</Link>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Изменение пароля</h1>

      <div className={styles.card}>
        <form className={styles.form} onSubmit={handleSubmit(handleRequest)}>
          <label htmlFor="password">
            <p>Новый пароль</p>

            <input
              {...register('password', { required: true })}
              type="password"
              placeholder="Введите новый пароль"
            />
          </label>

          <div className={styles.error}>
            {
              result?.errors?.list?.map((str) => (
                <p key={str}>{str}</p>
              ))
            }
          </div>

          <button type="submit">Отправить</button>
        </form>
      </div>
    </div>
  );
};

export default observer(PasswordResetConfirm);
