import { useEffect } from 'react';
import { observer } from 'mobx-react'
import { useInView } from 'react-intersection-observer';
import Table from 'rc-table'
import useStore from 'hooks/useStore'
import emptyCart from 'assets/empty-cart.png'
import { columns } from './config'

const CartTable = (props) => {
  const { styles } = props
  const { breakpoint } = useStore('GlobalStore')
  const { getNextPageItems, cartData, isLoadingNextPage } = useStore('CartStore')
  const isDesktop = breakpoint === 'desktop'

  const { ref, inView } = useInView({
    threshold: 0.8,
    delay: 300,
  });

  useEffect(() => {
    let ready = true

    if (ready && inView) {
      getNextPageItems()
    }

    return () => { ready = false }
  }, [getNextPageItems, inView])

  const getRowClassName = (record) => {
    const condition = !record.product.active;

    return condition ? styles.isLimit : ''
  };

  return (
    <>
      <Table
        className={`${styles.table} ${isLoadingNextPage ? styles.loading : ''}`}
        columns={columns({ ...props, isDesktop })}
        data={cartData.lines}
        rowKey={(record) => record.key}
        rowClassName={getRowClassName}
        tableLayout={isDesktop ? 'auto' : 'fixed'}
        emptyText={<img className={styles.empty} src={emptyCart} alt="" />}
      />

      {/* Update list trigger */}
      <div ref={ref} />
    </>
  )
}

export default observer(CartTable)
