import useStore from 'hooks/useStore';

const useStoreBreakpoint = () => {
  const { breakpoint } = useStore('GlobalStore')

  switch (breakpoint) {
    case 'desktop':
      return 60

    case 'tablet':
      return 120

    case 'mobile':
      return 115

    default:
      return 60
  }
}

export default useStoreBreakpoint
