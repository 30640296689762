import { useState } from 'react';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import Image from 'components/shared/Image';
import { ReactComponent as Camera } from 'assets/svg/camera.svg';
import styles from './styles.module.scss';

const UserAvatar = ({ isEditMode }) => {
  const { user, updateUser } = useStore('UserStore');

  const [userPhoto, setUserPhoto] = useState(null);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      updateUser({
        photo: file,
      });

      setUserPhoto(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.userAvatar}>
        <Image src={userPhoto || user?.photo} alt="Фото пользователя" />

        {isEditMode && (
          <label htmlFor="picture" type="button" className={styles.btnChangeImage}>
            <Camera fill="white" stroke="white" />
            <input id="picture" type="file" onChange={handlePhotoChange} />
          </label>
        )}
      </div>
    </div>
  );
};

export default observer(UserAvatar);
