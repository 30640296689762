import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { routeLinks } from 'config/routeLinks';

import useStore from 'hooks/useStore';
import ManagerCard from 'components/manager/ManagerCard';

import { ReactComponent as Home } from 'assets/svg/home.svg';
import { ReactComponent as User } from 'assets/svg/user.svg';
import { ReactComponent as Manager } from 'assets/svg/manager.svg';
import { ReactComponent as Heart } from 'assets/svg/heart.svg';
import { ReactComponent as Cart } from 'assets/svg/cart.svg';

import Item from './Item';
import style from './style.module.scss';

const UserMenu = () => {
  const { breakpoint } = useStore('GlobalStore')
  const { count, sum } = useStore('CartStore');
  const { user, count: countFavorite } = useStore('UserStore');
  const isStuff = user?.is_stuff
  const isDesktop = breakpoint === 'desktop'

  const menuList = useMemo(() => [
    {
      key: 'user-menu-home-id-1',
      hide: isDesktop,
      linkTo: routeLinks.home,
      icon: <Home />,
      isCounter: false,
      counterData: null,
      text: 'Главная',
    },
    {
      key: 'user-menu-profile-id-3',
      hide: false,
      linkTo: routeLinks.profile,
      icon: <User />,
      isCounter: false,
      counterData: null,
      customCounter: <span className={`${style.counter} ${style.isUserVerify}`} />,
      text: user.first_name || 'Пользователь',
    },
    {
      key: 'user-menu-favorites-id-5',
      hide: false,
      linkTo: routeLinks.favorites,
      icon: <Heart />,
      isCounter: true,
      counterData: countFavorite,
      text: 'Избранное',
    },
    {
      key: 'user-menu-cart-id-6',
      hide: false,
      linkTo: routeLinks.cart,
      icon: <Cart />,
      isCounter: true,
      counterData: count,
      text: sum > 0 ? `${sum} p.` : 'Корзина',
    },
    {
      key: 'user-menu-manager-id-4',
      hide: isDesktop || isStuff,
      icon: <ManagerCard isShow position="top"><Manager /></ManagerCard>,
      isNotLink: true,
      text: 'Менеджер',
    },
  ], [count, countFavorite, isDesktop, sum, user, isStuff]);

  const renderMenuList = () => {
    const filteredMenuList = menuList.filter((props) => !props.hide);
    const countRenderedItems = filteredMenuList.length;
    return (
      <div className={style.menu} style={{ gridTemplateColumns: `repeat(${countRenderedItems}, 1fr)` }}>
        {filteredMenuList.map((props) => <Item {...props} />)}
      </div>
    );
  };

  return renderMenuList();
};

export default observer(UserMenu);
