export const options = [
  { label: 'По умолчанию', value: null },
  {
    value: 'price',
    label: 'Только дешевле',
  },
  {
    value: '-price',
    label: 'Только дороже',
  },
];
