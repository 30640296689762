import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import PageNotFound from 'routes/PageNotFound';
import Container from 'components/shared/Container';
import Title from 'components/shared/Title';
import Card from 'components/Card';
import Loader from 'components/shared/Loader';

import styles from './styles.module.scss';

const MultiCategories = () => {
  const { catalogSlug } = useParams();
  const { isLoading, data, requestCategories } = useStore('MultiCategoriesStore');
  const { menu } = useStore('GlobalStore');
  const id = menu.find((item) => item.url === `/categories/${catalogSlug}/`)?.id;

  useEffect(() => {
    if (id) {
      requestCategories(id);
    }
  }, [requestCategories, id]);

  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <Loader isLoading={isLoading} />
      </div>
    );
  }

  if (!isLoading && !data) {
    return <PageNotFound />;
  }

  return (data || []).map((block) => (
    <Container key={block.title}>
      <Title>
        {block?.title}
      </Title>

      <div className={styles.catalogList}>
        {
            block?.categories?.map((item) => (
              <Card
                key={item.id}
                preview={item.preview}
                name={item.title}
                catalogId={item.id}
                slug={`/products/${item.catalog}/${item.slug}`}
                isSubCatalogCard
              />
            ))
          }
      </div>
    </Container>
  ))
};

export default observer(MultiCategories);
