import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useStore from 'hooks/useStore';
import styles from './styles.module.scss';

const breakpoints = {
  768: {
    spaceBetween: 30,
  },
};

const HeaderMenu = () => {
  const { menu } = useStore('GlobalStore');
  const { user } = useStore('UserStore');

  return (
    <div className={styles.headerMenu} style={user.is_stuff ? { maxWidth: '100%' } : {}}>
      <Swiper
        className={styles.swiperMain}
        modules={[Mousewheel, FreeMode]}
        spaceBetween={15}
        breakpoints={breakpoints}
        slidesPerView="auto"
        mousewheel
        freeMode
      >
        {menu.map(({ url, title }) => (
          <SwiperSlide className={styles.menuItem} key={url}>
            <div>
              <Link to={url} className={styles.menuLinkItem}>
                {title}
              </Link>
            </div>
          </SwiperSlide>
        ))}

      </Swiper>
    </div>
  );
};

export default observer(HeaderMenu);
