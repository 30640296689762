import {
  useState,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from 'components/shared/Container';
import SideMenu from 'components/SideMenu';
import ProductCard from 'modules/ProductCard';
import Filters from 'components/Filters';
import FiltersByMarker from 'components/FiltersByMarker';
import FilterModal from 'components/FilterModal';
import FilterOrdering from 'modules/FilterOrdering';
import Title from 'components/shared/Title';
import Loader from 'components/shared/Loader';
import useStore from 'hooks/useStore';

import { ReactComponent as SVG1 } from 'assets/svg/icon-block-render-1.svg';
import { ReactComponent as SVG2 } from 'assets/svg/icon-block-render-2.svg';
import { ReactComponent as SVG3 } from 'assets/svg/icon-block-render-3.svg';
import noResults from 'assets/no-results.png';
import Pagination from 'components/Pagination';
import { options } from './config';
import styles from './styles.module.scss';

const getTreeSideMenu = (arr) => {
  const idMap = new Map();

  arr.forEach((obj) => {
    idMap.set(obj.id, { ...obj, child: [] });
  });

  arr.forEach((obj) => {
    const parentID = obj.parent_category;

    if (parentID !== null) {
      const childByID = idMap.get(obj.id);

      idMap.get(parentID)?.child.push(childByID);
    }
  });

  return Array.from(idMap.values());
};

const SearchProductList = ({
  isLoadingProducts,
  isNextPageLoading,
  products,
  isNextPageAvailable,
  currentPage,
  handlePageClick,
  showMoreProducts,
  totalPages,
}) => {
  const [selectedView, setSelectedView] = useState('box');
  const { breakpoint } = useStore('GlobalStore');
  const isDesktop = breakpoint === 'desktop';
  const [searchParams] = useSearchParams();
  const isEmptyResult = !products?.results || products?.results?.length <= 0;
  const brands = products?.brands;

  const tree = useMemo(() => {
    const categories = products?.categories || [];

    return getTreeSideMenu(categories)
  }, [products]);

  return (
    <Container>
      <Title
        extra={isDesktop ? <FiltersByMarker /> : null}
      >
        {
                    searchParams.has('search')
                      ? `Результаты поиска «${searchParams.get('search')}»`
                      : 'Результаты поиска'
                }
      </Title>

      <div className={styles.wrapper}>
        <div className={styles.filtersWrapper}>
          {tree && <SideMenu tree={tree} />}

          {isDesktop ? <Filters brandsList={brands} handlePageClick={handlePageClick} /> : null}
        </div>

        {!isDesktop ? <FiltersByMarker /> : null}

        <div className={styles.productListWrapper}>
          <FilterOrdering
            customOption={options}
          >
            {!isDesktop ? <FilterModal brandsList={brands} handlePageClick={handlePageClick} /> : null}

            <div className={styles.viewSelect}>
              <button
                className={`${styles.viewItem} ${selectedView === 'box' ? styles.active : ''}`}
                type="button"
                onClick={() => setSelectedView('box')}
              >
                <SVG1 />
              </button>

              {
                                isDesktop && (
                                <button
                                  className={`${styles.viewItem} ${
                                    selectedView === 'smallbox' ? styles.active : ''
                                  }`}
                                  type="button"
                                  onClick={() => setSelectedView('smallbox')}
                                >
                                  <SVG2 />
                                </button>
                                )
                            }

              <button
                className={`${styles.viewItem} ${selectedView === 'row' ? styles.active : ''}`}
                type="button"
                onClick={() => setSelectedView('row')}
              >
                <SVG3 />
              </button>
            </div>
          </FilterOrdering>

          <Loader isLoading={isLoadingProducts}>
            <div className={`${styles.grid} ${isEmptyResult ? styles.empty : styles[selectedView]}`}>
              {
                                isEmptyResult ? (
                                  <img src={noResults} alt="no-results" />
                                ) : products?.results?.map((product) => (
                                  <div key={product.id}>
                                    <ProductCard
                                      cardView={selectedView}
                                      data={product}
                                    />
                                  </div>
                                ))
                            }
              {(isLoadingProducts || isNextPageLoading) && (
                <div className="products-grid__loading">
                  <div className="lds-roller">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              )}
            </div>
          </Loader>
          {!isLoadingProducts && totalPages > 1
                        && (
                        <Pagination
                          isDesktop={isDesktop}
                          currentPage={currentPage}
                          handlePageClick={handlePageClick}
                          isNextPageAvailable={isNextPageAvailable}
                          isNextPageLoading={isNextPageLoading}
                          totalPages={totalPages}
                          showMoreProducts={showMoreProducts}
                        />
                        )}
        </div>
      </div>
    </Container>
  );
};

export default SearchProductList;
