import { useEffect, useId, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import Catalogs from 'modules/Catalogs';
import Container from 'components/shared/Container';
import Image from 'components/shared/Image';
import Title from 'components/shared/Title';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-round.svg';

import Modal from 'components/shared/Modal';
import Video from 'modules/ProductCard/ModalDescription/Video';
import styles from './styles.module.scss';

const MainPage = () => {
  const { breakpoint, banners, sliders, videosliders, isLoadCriticalData } = useStore('GlobalStore');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isDesktop = breakpoint === 'desktop';
  const slidID = useId();
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    const anchor = hash && document.querySelector(hash);
    if (anchor && isLoadCriticalData) {
      setTimeout(() => {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadCriticalData]);

  return (
    <Container>
      <div className={styles.sliderWrapper}>
        <div className={styles.banners}>
          {isDesktop
            && banners.map(({ img, url }) => (
              <Link
                key={img}
                className={styles.bannersItem}
                to={`products${url}`}
              >
                <Image src={img} alt="Promotion" />
              </Link>
            ))}
        </div>

        {isLoadCriticalData && (
          <Swiper
            className={styles.swiperMain}
            modules={[Pagination, Autoplay, Navigation]}
            spaceBetween={10}
            slidesPerView="1"
            loop
            navigation
            lazy="true"
            initialSlide={1}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {sliders.map(({ img, url }) => (
              <SwiperSlide
                className={styles.menuItem}
                key={slidID + img || '_slide'}
              >
                <div key={img} className={styles.sliderItem}>
                  <a href={url || '#'}>
                    <Image src={img} alt="banner image" />
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div id="catalog" />
      <Title>Каталог товаров</Title>

      <Catalogs />

      <div className={styles.swiperVideoWrapper}>
        <div className={styles.swiperVideoWrapperHeader}>
          <h3>Последнее видео</h3>

          <div className={styles.controls}>
            <div className="swiperVideoButtonPrev">
              <ArrowIcon />
            </div>
            <div className="swiperVideoButtonNext">
              <ArrowIcon />
            </div>
          </div>
        </div>

        <Swiper
          className={styles.swiperVideo}
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: '.swiperVideoButtonPrev',
            nextEl: '.swiperVideoButtonNext',
          }}
          spaceBetween={10}
          loop
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
              slidesPerGroup: 1,
            },
          }}
        >
          {videosliders.map(({ img, order, url }) => (
            <SwiperSlide key={order}>
              <div className={styles.swiperVideoItem}>
                <Modal
                  isOpen={isOpenModal[order] || false}
                  setIsOpen={(val) => setIsOpenModal((prev) => ({ ...prev, [order]: val }))}
                >
                  <Video videoPath={url} />
                </Modal>

                <button
                  type="button"
                  className={styles.btnZoomIn}
                  onClick={() => setIsOpenModal((prev) => ({
                    ...prev,
                    [order]: !prev[order],
                  }))}
                >
                  <Image src={img} alt="banner image" />

                  <div className={styles.playbtn}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="50%"
                      height="50%"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polygon points="5 3 19 12 5 21 5 3" />
                    </svg>
                  </div>
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
};

export default observer(MainPage);
