import Image from 'components/shared/Image';
import commentIcon from 'assets/comment.png';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'components/shared/Modal';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ERROR_TOAST_CONFIG, SUCCESS_TOAST_CONFIG } from 'config';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import { cartlinesPartialUpdate } from 'services/api/v1/cartlines';
import styles from './styles.module.scss';

const CommentButton = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { updateProductValue, cartData } = useStore('CartStore');

  const cartItem = useMemo(() => cartData.lines.find((item) => item.id === id), [cartData.lines, id]);
  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    if (cartItem) {
      reset({ comment: cartItem.comment });
    }
  }, [cartItem, reset]);

  const updateCartLine = useCallback(
    async (val) => {
      try {
        const { data } = await cartlinesPartialUpdate(id, {
          product_id: cartItem?.product?.id,
          comment: val,
        });

        updateProductValue(data);
        reset({ comment: data.comment });

        return data;
      } catch (error) {
        toast('Что-то с обновлением данных', ERROR_TOAST_CONFIG);
      }

      return {};
    },
    [id, cartItem, updateProductValue, reset],
  );

  const onSend = async ({ comment: formComment }) => {
    try {
      await updateCartLine(formComment || '');
      toast.success('Ваш комментарий был сохранен!', SUCCESS_TOAST_CONFIG);
      setIsOpenModal(false);

      reset();
    } catch (error) {
      toast.error(
        'Что-то пошло не так, проверьте поля формы.',
        ERROR_TOAST_CONFIG,
      );
    }
  };

  return (
    <>
      <Modal isOpen={isOpenModal} hideCloser style={{ maxWidth: '500px' }}>
        <form onSubmit={handleSubmit(onSend)} className={styles.form}>
          <Controller
            control={control}
            name="comment"
            render={({ field: { value, onChange, onBlur } }) => (
              <textarea
                id="comment"
                rows={6}
                maxLength={500}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="Напишите текст комментария"
              />
            )}
          />

          <div className={styles.controls}>
            <button
              className={styles.btnCloseModal}
              type="button"
              onClick={() => setIsOpenModal(false)}
            >
              Закрыть
            </button>
            <button className={styles.btnSaveModal} type="submit">
              Сохранить
            </button>
          </div>
        </form>
      </Modal>
      <button
        type="button"
        className={`${cartItem?.comment ? '' : styles.empty} ${styles.btn}`}
        onClick={() => setIsOpenModal(true)}
      >
        <Image src={commentIcon} alt="comment" />
      </button>
    </>
  );
};

export default observer(CommentButton);
