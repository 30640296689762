import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import SideMenuItemsList from './SideMenuItemsList';

const MAX_VISIBLE_ELEMENTS = 5;

const SideMenu = ({ tree = [] }) => {
  const { sideMenuTree, setCatalogSlug, dictionaries, breakpoint } = useStore('GlobalStore');
  const { catalogSlug, '*': nestedPath } = useParams()
  const isDesktop = breakpoint === 'desktop';
  const [showAll, setShowAll] = useState(isDesktop);

  const nestedPathArray = nestedPath?.split('/').filter((part) => part !== '') || [];
  const slug = nestedPathArray[nestedPathArray.length - 1];
  const currentTree = tree?.length > 1 ? tree : sideMenuTree

  useEffect(() => {
    setCatalogSlug(catalogSlug);
  }, [catalogSlug, setCatalogSlug]);

  const handelShowAll = () => setShowAll((val) => !val);

  const shouldShowButton = currentTree.length > MAX_VISIBLE_ELEMENTS;

  return (
    <>
      <div className={styles.list}>
        <SideMenuItemsList
          tree={currentTree}
          catalogSlug={catalogSlug}
          categorySlug={slug}
          originalCategories={dictionaries}
          showAll={showAll}
          countVisibleElements={MAX_VISIBLE_ELEMENTS}
        />
      </div>
      {!isDesktop && shouldShowButton && (
      <button className={`btn-show-all ${styles.btnShowAll}`} onClick={handelShowAll} type="button">
        {showAll ? 'Скрыть ' : 'Показать '}
        все категории
      </button>
      )}
    </>
  );
};

export default observer(SideMenu);
