import React, { useState } from 'react';
import ProductCounter from 'components/ProductCounter';

const ProductCounterWrapper = ({ quantity, count, ...props }) => {
  const [cartCount, setСartCount] = useState(
    quantity || count
      ? String(quantity || count)
      : String(String(props.minQuantity) || count),
  );

  return (
    <ProductCounter
      {...props}
      value={cartCount}
      setValue={setСartCount}
    />
  )
};

export default ProductCounterWrapper;
