import React from 'react';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import styles from './styles.module.scss';

const UserProfile = () => {
  const { user } = useStore('UserStore');

  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>
        {`${user?.last_name} ${user?.first_name} ${user?.middle_name || ''}`}
      </div>

      <div className={styles.phone}>
        {user?.email || ''}
      </div>

      <div className={styles.phone}>
        {user?.phone || ''}
      </div>

      <div className={styles.additionalInfo}>
        {user?.additional_information || ''}
      </div>
    </div>
  );
};

export default observer(UserProfile);
