/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { routes } from 'config';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to={routes.notFound()} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
