import { useState } from 'react';
import ProductCard from 'modules/ProductCard';
import FilterOrdering from 'modules/FilterOrdering';
import Loader from 'components/shared/Loader';
import FilterModal from 'components/FilterModal';
import ViewButton from 'components/ViewButton';
import useStore from 'hooks/useStore';
import { ReactComponent as SVG1 } from 'assets/svg/icon-block-render-1.svg';
import { ReactComponent as SVG2 } from 'assets/svg/icon-block-render-2.svg';
import { ReactComponent as SVG3 } from 'assets/svg/icon-block-render-3.svg';
import noResults from 'assets/no-results.png';
import styles from './styles.module.scss';
import Pagination from '../../components/Pagination';

const getSelectedView = (isDesktop) => {
  const localStorageValue = localStorage.getItem('productListView')

  if (localStorageValue) {
    return localStorage.getItem('productListView')
  }

  return isDesktop ? 'box' : 'smallbox'
}

const ProductList = ({
  category,
  isLoadingProducts,
  isNextPageLoading,
  products,
  isNextPageAvailable,
  currentPage,
  handlePageClick,
  showMoreProducts,
  totalPages,
}) => {
  const { breakpoint } = useStore('GlobalStore');

  const isDesktop = breakpoint === 'desktop';

  const [selectedView, setSelectedView] = useState(getSelectedView(isDesktop));

  const handleSelectView = (view) => {
    setSelectedView(view)
    localStorage.setItem('productListView', view)
  };

  const isEmptyResult = !products?.results || products?.results?.length <= 0;

  return (
    <div className={styles.productListWrapper}>
      <FilterOrdering>
        {!isDesktop && (
        <FilterModal category={category} products={products} />
        )}

        <div className={styles.viewSelect}>
          {isDesktop && (
            <ViewButton
              view="box"
              selectedView={selectedView}
              handleSelectView={handleSelectView}
              svgComponent={<SVG1 />}
            />
          )}

          <ViewButton
            view="smallbox"
            selectedView={selectedView}
            handleSelectView={handleSelectView}
            svgComponent={<SVG2 />}
          />

          <ViewButton
            view="row"
            selectedView={selectedView}
            handleSelectView={handleSelectView}
            svgComponent={<SVG3 />}
          />
        </div>
      </FilterOrdering>

      <Loader isLoading={isLoadingProducts}>
        <div
          className={`products-grid ${isEmptyResult ? styles.empty : selectedView}`}
        >
          {isEmptyResult ? (
            <img src={noResults} alt="no-results" />
          ) : (
            products?.results?.map((product) => (
              <div key={product.id}>
                <ProductCard cardView={selectedView} data={product} />
              </div>
            ))
          )}

          {(isLoadingProducts || isNextPageLoading) && (
            <div className="products-grid__loading">
              <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          )}
        </div>
      </Loader>
      {!isLoadingProducts && totalPages > 1
                && (
                <Pagination
                  currentPage={currentPage}
                  handlePageClick={handlePageClick}
                  isNextPageAvailable={isNextPageAvailable}
                  isNextPageLoading={isNextPageLoading}
                  totalPages={totalPages}
                  showMoreProducts={showMoreProducts}
                  isDesktop={isDesktop}
                />
                )}
    </div>
  );
};

export default ProductList;
