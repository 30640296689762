import { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import ProductCard from 'modules/ProductCard';
import Loader from 'components/shared/Loader';
import noFavorites from 'assets/no-favorites.png';
import styles from './styles.module.scss';

const FavoritesContainer = () => {
  const { getFavorite, favorite, isDataLoading, user } = useStore('UserStore');
  const { prepay } = user;

  useEffect(() => {
    let ready = true;

    if (ready) {
      getFavorite();
    }

    return () => { ready = false; };
  }, [getFavorite, prepay]);

  const isEmptyResult = favorite?.results?.length <= 0;

  return (
    <div
      className={`${styles.grid} ${isDataLoading ? styles.loading : ''} ${
        isEmptyResult ? styles.empty : ''
      }`}
    >
      <Loader isLoading={isDataLoading}>
        {isEmptyResult ? (
          <img src={noFavorites} alt="no favorite" />
        ) : (
          favorite?.results?.map((product) => (
            <ProductCard key={product.id} data={product} />
          ))
        )}
      </Loader>
    </div>
  );
};

export default observer(FavoritesContainer);
