import { routeLinks } from 'config/routeLinks';

export const menu = [
  {
    path: routeLinks.profile,
    title: 'Личные данные',
    isForClientOnly: false,
  },
  {
    path: routeLinks.favorites,
    title: 'Избранное',
    isForClientOnly: false,
  },
  {
    path: routeLinks.orders,
    title: 'История заказов',
    isForClientOnly: false,
  },
  {
    path: routeLinks.ordered,
    title: 'Ранее заказывали',
    isForClientOnly: false,
  },
  {
    path: routeLinks.siteFeedback,
    title: 'Отзыв о сайте',
    isForClientOnly: true,
  },
];
