export const findPathByIdRecursive = (data, slug) => {
  const results = data.results || [];

  for (let i = 0; i < results.length; i += 1) {
    const result = results[i];
    const categories = result.categories || [];

    if (result?.slug === slug) {
      return [result];
    }

    for (let j = 0; j < categories.length; j += 1) {
      const category = categories[j];

      if (category.slug === slug) {
        // Категория найдена, возвращаем путь до нее
        if (result.id) {
          return [result, category];
        }
      }

      if (category.categories) {
        // Рекурсивно ищем категорию в подкатегориях
        const path = findPathByIdRecursive({ results: category.categories }, slug);

        if (path.length > 0) {
          // Категория найдена в подкатегории, добавляем текущую категорию в путь
          if (result.id) {
            return [result, ...path];
          }
        }
      }
    }
  }

  return [];
};
