import { useState } from 'react';
import noImage from 'assets/no-image.jpg';

const Image = ({
  src, alt, classname, ...rest
}) => {
  const [error, setError] = useState(false);

  const path = error ? noImage : src || noImage;

  const styles = error ? {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  } : {
    width: 'auto',
    margin: '0 auto',
  };

  return (
    <img
      loading="lazy"
      className={classname}
      src={path}
      alt={alt}
      onError={() => {
        setError(true);
      }}
      style={styles}
      {...rest}
    />
  );
};

export default Image;
