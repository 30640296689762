const debounce = (callBack, delay = 1000) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      callBack(...args);
    }, delay);
  };
};

export default debounce;
