import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { excludeSearchParams } from 'helpers/excludeSearchParams';
import Marker from 'components/Markers';
import styles from './style.module.scss';

const FiltersByMarker = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleAddQuery = (key) => {
    const paramsWithOut = excludeSearchParams(searchParams, [key]);

    if (searchParams.has(key) && searchParams.get(key) === '0') {
      setSearchParams(paramsWithOut);
    } else {
      setSearchParams([...paramsWithOut, [key, '0']]);
    }

    if (!searchParams.has(key)) {
      setSearchParams([...paramsWithOut, [key, '1']]);
    }
  };

  const sortTextNew = useMemo(() => {
    if (searchParams.has('new')) {
      return searchParams.get('new') === '1'
        ? 'Только Новинки'
        : 'Без Новинок';
    }
    return 'Новинки';
  }, [searchParams]);

  const sortTextPopular = useMemo(() => {
    if (searchParams.has('popular')) {
      return searchParams.get('popular') === '1'
        ? 'Только Хиты'
        : 'Без Хитов';
    }
    return 'Хит';
  }, [searchParams]);

  const sortTextSale = useMemo(() => {
    if (searchParams.has('sale')) {
      return searchParams.get('sale') === '1'
        ? 'Только по Акции'
        : 'Без Акции';
    }
    return 'Акция';
  }, [searchParams]);

  return (
    <div className={styles.filtersByMarker}>
      <button
        type="button"
        onClick={() => handleAddQuery('new')}
      >
        <Marker.New text={sortTextNew} />
      </button>

      <button
        type="button"
        onClick={() => handleAddQuery('popular')}
      >
        <Marker.Popular text={sortTextPopular} />
      </button>

      <button
        type="button"
        onClick={() => handleAddQuery('sale')}
      >
        <Marker.Sale text={sortTextSale} />
      </button>
    </div>
  );
};

export default FiltersByMarker;
