import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import useStore from 'hooks/useStore'
import Tooltip from 'components/shared/Tooltip'
import styles from './styles.module.scss'

const BaseInfo = ({ data, setIsOpen, cardView }) => {
  const { dictionaries, breakpoint } = useStore('GlobalStore')
  const { '*': nestedPath } = useParams();

  const isDesktop = breakpoint === 'desktop'
  const isNotSmallBoxView = cardView !== 'smallbox'
  const isCardViewRow = cardView === 'row'

  const { title, category, brand, article_number, params } = data

  const getSlugById = useMemo(() => {
    const [, , catalogID, , categoryID] = category.url.split('/')

    const findCatalog = dictionaries?.results?.find(
      (item) => item.id === Number(catalogID),
    )
    const findCategory = findCatalog?.categories?.find(
      (item) => item.id === Number(categoryID),
    )

    const catalogSlug = findCatalog?.slug || ''
    const categorySlug = findCategory?.slug || ''

    return nestedPath ? `${nestedPath}${categorySlug}/` : `/products/${catalogSlug}/${categorySlug}/`
  }, [category.url, dictionaries?.results, nestedPath])

  return (
    <div className={`${styles.infoWrapper} ${isCardViewRow ? styles.infoWrapperRow : ''}`}>
      <Tooltip tooltipText={title} position="Top">
        <h3 className={styles.title}>{title}</h3>
      </Tooltip>

      <ul className={styles.baseInfo}>
        <li className={styles.infoItem}>
          Раздел:
          {' '}
          <Link to={getSlugById}>{category?.title}</Link>
        </li>

        <li className={styles.infoItem}>
          Бренд:
          {' '}
          <span>{brand?.title}</span>
        </li>

        {isNotSmallBoxView && (
          <li className={styles.infoItem}>
            Артикул:
            {' '}
            <span>{article_number}</span>
          </li>
        )}

        {isNotSmallBoxView && (
          <li className={styles.infoItem}>
            Размер:
            {' '}
            <span>{params['Размер']}</span>
          </li>
        )}
      </ul>

      {((!isDesktop && isCardViewRow) || isDesktop) && (
        <button
          type="button"
          className={styles.buttonMoreInfo}
          onClick={() => setIsOpen((val) => !val)}
        >
          Описание +
        </button>
      )}
    </div>
  )
}

export default observer(BaseInfo)
