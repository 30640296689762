import { useState } from 'react';
import { Link } from 'react-router-dom';
import image from 'assets/not-found.png';
import styles from './styles.module.scss';

const PageNotFound = () => {
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);

  const handleMouseMove = (event) => {
    setPositionX(event.clientX);
    setPositionY(event.clientY);
  };

  return (
    <div className={styles.wrapper} onMouseMove={handleMouseMove}>
      <Link
        className={styles.link}
        style={{
          transform: `translate(${-positionX / 10}px, ${-positionY / 20}px)`,
        }}
        to="/"
      >
        Вернуться
      </Link>
      <img
        className={styles.background}
        style={{
          transform: `translate(${positionX / 20}px, ${positionY / 20}px)`,
        }}
        src={image}
        alt="not found page"
      />
    </div>
  );
};

export default PageNotFound;
