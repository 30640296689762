import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { excludeSearchParams } from 'helpers/excludeSearchParams'
import Dropdown from 'components/shared/Dropdown'
import { options } from './config'
import styles from './styles.module.scss'

const FilterOrdering = ({ children, customOption, isCartFilter = false }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleOption = (param) => {
    const withOutParams = excludeSearchParams(searchParams, ['ordering'])

    if (param.value !== null) {
      setSearchParams([...withOutParams, ['ordering', param?.value]])
    }

    if (param.value === null) {
      setSearchParams(withOutParams)
    }
  }

  const hasDefaultFilterOnLoad = useMemo(() => {
    const defaultOrderingParam = searchParams.get('ordering')
    const currentOptions = customOption || options
    const defaultOption = currentOptions.find(
      (item) => item.value === defaultOrderingParam,
    )

    return defaultOption?.label
  }, [customOption, searchParams])

  return (
    <div className={`${styles.wrapper} ${isCartFilter ? styles.columnView : ''}`}>
      <div className={styles.filter}>
        <span>Сортировка:</span>

        <Dropdown
          options={customOption || options}
          callback={handleOption}
          defaultLabel={hasDefaultFilterOnLoad || options[0].label}
        />
      </div>

      {children}
    </div>
  )
}

export default FilterOrdering
