import { useMemo, useState } from 'react'
import useStore from 'hooks/useStore'
import Image from 'components/shared/Image'
import styles from './styles.module.scss'

const ImagesList = (props) => {
  const { images = [], id, alt, cardView } = props
  const { breakpoint } = useStore('GlobalStore')
  const [currentSrcImage, setCurrentSrcImage] = useState(
    images[0]?.preview || '',
  )
  const isMobile = breakpoint === 'mobile'

  const handleHoverImageListItem = (e) => {
    setCurrentSrcImage(e.target.dataset.imageSrc)
  }

  const renderListImage = useMemo(() => images.map(({ image, preview }) => (
    <li
      key={`${id}_${preview}`}
      className={styles.listItem}
      data-image-src={preview || image}
      onMouseEnter={handleHoverImageListItem}
    />
  )), [id, images])

  return (
    <div className={`${styles.imagesWrapper} ${styles[cardView]}`}>
      <Image className={styles.cardImage} src={currentSrcImage} alt={alt} />

      {!isMobile && (<ul className={styles.imageList}>{renderListImage}</ul>)}
    </div>
  )
}

export default ImagesList
