import styles from './styles.module.scss';

const Title = ({ children, extra }) => (
  <div className={styles.wrapper}>
    <h1 className={styles.title}>{children}</h1>
    {extra}
  </div>
);

export default Title;
