import instance from 'services/request';

export const getProducts = (params) => instance.get('/v1/products/', params);

export const getProduct = ({ id }) => instance.get(`/v1/products/${id}/`);

export const productsSearchGet = (params) => instance.get('/v1/products/', params);

export const productsUpdate = (params) => instance.put('/v1/products/{id}/', params);

export const productsPartialUpdate = (params) => instance.patch('/v1/products/{id}/', params);
