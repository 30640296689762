/* eslint-disable react/button-has-type */
import styles from './styles.module.scss';

const Button = (props) => {
  const {
    colorSchema,
    maxWidth = false,
    size,
    icon,
    onClick,
    children,
    type = 'button',
    buttonRef,
  } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${styles.button} ${size || ''} ${maxWidth ? styles['w-100'] : ''} ${styles[colorSchema] || ''}`}
      ref={buttonRef}
    >
      {icon}
      {children}
    </button>
  );
};

export default Button;
