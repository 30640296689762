import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import useStore from 'hooks/useStore';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Textarea from 'components/shared/Textarea';
import Checkbox from 'components/shared/Checkbox';
import EditPasswordForm from './EditPassword';

import styles from './styles.module.scss';

const UserProfileForm = ({ onEdit }) => {
  const { user, updateUser } = useStore('UserStore');
  const [isProfileEdit, setIsProfileEdit] = useState(true);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: user,
  });

  useEffect(() => {
    let active = true;

    if (active) {
      Object.entries(user).forEach(([key, val]) => {
        if (key !== 'photo') {
          setValue(key, val);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [setValue, user]);

  const onSend = async (values) => {
    delete values.photo;
    try {
      await updateUser(values);
      onEdit((val) => !val);
    } catch ({ response }) {
      const err = Object.entries(response.data);

      err.forEach(([key, val]) => {
        setError(key, {
          type: 'server',
          message: val,
        });
      });
    }
  };

  const handlePasswordForm = () => (setIsProfileEdit((val) => !val));

  return (
    <>
      {
        isProfileEdit ? (
          <form onSubmit={handleSubmit(onSend)}>
            <Input
              label="Фамилия"
              register={register}
              required
              name="last_name"
              isError={errors.last_name}
              errorMessage={errors.last_name?.message}
            />

            <Input
              label="Имя"
              register={register}
              required
              name="first_name"
              isError={errors.first_name}
              errorMessage={errors.first_name?.message}
            />

            <Input
              label="Отчество"
              register={register}
              name="middle_name"
            />

            <Input label="Телефон" register={register} name="phone" />

            <Textarea
              id="additional_information"
              name="additional_information"
              label="Дополнительная информация"
              register={register}
            />

            <Checkbox
              name="subscribed"
              type="checkbox"
              label="Получать уведомления о заказах на почту?"
              register={register('subscribed')}
            />
            <Button colorSchema="green" type="submit">Сохранить</Button>
          </form>
        ) : (<EditPasswordForm />)
      }

      <div className={styles.changeFormBtnWrapper}>
        <Button size="sm" type="button" onClick={handlePasswordForm}>
          Изменить
          {' '}
          {!isProfileEdit ? 'профиль' : 'пароль' }
        </Button>
      </div>
    </>
  );
};

export default observer(UserProfileForm);
