// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import ProfileTemplate from 'components/templates/ProfileTemplate';
import User from 'components/User';
import DashboardCard from 'components/Dashboard/Card';

import styles from './styles.module.scss';

const ProfilePage = () => {
  const { user: userData } = useStore('UserStore');

  return (
    <ProfileTemplate>
      <div className={styles.grid}>
        <div className={styles.tile}>
          <User />
        </div>

        <div className={styles.tileSmall}>
          <DashboardCard
            title="Общая сумма купленного товара"
          >
            <CountUp
              className={styles.largeText}
              end={userData?.total_orders_sum}
              duration={2}
              decimals={2}
              prefix="BYN "
            />
          </DashboardCard>
        </div>
      </div>
    </ProfileTemplate>
  );
};

export default observer(ProfilePage);
