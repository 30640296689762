import ProfileTemplate from 'components/templates/ProfileTemplate';
import Orders from 'modules/Orders';

const HistoryPage = () => (
  <ProfileTemplate>
    <Orders />
  </ProfileTemplate>
);

export default HistoryPage;
