import { Outlet } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';

const BaseTemplate = () => (
  <div className="main-wrapper">
    <Header />

    <main>
      <Outlet />
    </main>

    <Footer />
  </div>
);

export default BaseTemplate;
