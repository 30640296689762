import { New } from './New';
import { Popular } from './Popular';
import { Sale } from './Sale';

const Marker = {
  New,
  Popular,
  Sale,
};

export default Marker;
