import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import SearchProductList from './List'

const SearchProductsContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { requestProducts,
    requestNextPage,
    products,
    isLoading,
    isNextPageLoading,
    setCurrentSearchParams,
    isNextPageAvailable,
    currentPage,
    totalPages } = useStore('SearchStore');
  const { user } = useStore('UserStore');
  const { prepay } = user;

  const handleSearchParams = useCallback(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    setCurrentSearchParams(updatedSearchParams);
    setSearchParams(updatedSearchParams);

    requestProducts();
  }, [requestProducts, searchParams, setCurrentSearchParams, setSearchParams, prepay]);

  const showMoreProducts = () => {
    requestNextPage();
  };

  const handlePageClick = ({ selected }) => {
    requestProducts(selected);
  };

  useEffect(() => {
    handleSearchParams();
  }, [handleSearchParams]);

  return (
    <SearchProductList
      isLoadingProducts={isLoading}
      isNextPageLoading={isNextPageLoading}
      products={products}
      isNextPageAvailable={isNextPageAvailable}
      handlePageClick={handlePageClick}
      currentPage={currentPage}
      showMoreProducts={showMoreProducts}
      totalPages={totalPages}
    />
  )
}

export default observer(SearchProductsContainer)
