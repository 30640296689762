import { makeAutoObservable } from 'mobx';
import { loginUser } from 'services/auth';

const DEFAULT_ERROR_STATE = null;

export default class {
  isLoading = false;

  error = DEFAULT_ERROR_STATE;

  constructor() {
    makeAutoObservable(this);
  }

  loginUser = async (params) => {
    try {
      this.error = DEFAULT_ERROR_STATE;

      this.isLoading = true;

      await loginUser(params);
    } catch (err) {
      this.error = err.response.data.detail;
    } finally {
      this.isLoading = false;
    }
  };
}
