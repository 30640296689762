export const routeLinks = {
  notFound: '*',
  home: '/',
  login: '/login/',
  passwordReset: '/password/reset/',
  passwordResetConfirm: '/password/reset/confirm/',
  cart: '/cart/',
  profile: '/profile/',
  favorites: '/favorites/',
  discount: '/discount/',
  orders: '/orders/',
  products: '/products/',
  productsSearch: '/products/search/',
  categories: '/categories/',
  staff: '/staff/',
  siteFeedback: '/site-feedback/',
  ordered: '/products/ordered/',
};
