import styles from './styles.module.scss';

const Card = ({ title, children }) => (
  <div className={styles.card}>
    <div className={styles.title}>{title}</div>

    <hr />

    <div>{children}</div>
  </div>
);

export default Card;
