import { useState } from 'react';
import Modal from 'components/shared/Modal';
import Tooltip from 'components/shared/Tooltip';
import Loader from 'components/shared/Loader';
import styles from './styles.module.scss';
import Video from './Video';
import Gallery from './Gallery';
import { getFormattedData } from './helper';

const ModalDescription = ({
  isOpen,
  setIsOpen,
  images,
  params,
  videoPath,
  title,
  article,
  actions,
  isLoading,
}) => {
  const [isGallery, setIsGallery] = useState(true);

  const handleSwitchMediaContent = () => {
    setIsGallery((val) => !val);
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <Modal isOpen={isOpen} hideCloser>
      {isLoading || !title ? (
        <div className={styles.loaderWrapper}>
          <Loader isLoading />
        </div>
      ) : (
        <>
          <div className={styles.modalHeader}>
            {videoPath && (
              <button
                className={`${styles.btnSwitchModalContent} ${
                  isGallery ? styles.bgBlue : styles.bgGreen
                }`}
                type="button"
                onClick={handleSwitchMediaContent}
              >
                {isGallery ? 'Видео обзор' : 'Галерея'}
              </button>
            )}

            <button
              className={styles.btnCloseModal}
              type="button"
              onClick={handleCloseModal}
            >
              Закрыть
            </button>
          </div>

          <div className={styles.modalBody}>
            {((videoPath && isGallery) || !videoPath) && (
              <Tooltip
                tooltipText={title}
                position="Bottom"
                className={styles.tooltip}
              >
                <h2 className={styles.title} title={title}>
                  {title}
                </h2>
              </Tooltip>
            )}

            {isGallery ? (
              <Gallery images={images} />
            ) : (
              <Video videoPath={videoPath} />
            )}

            {((videoPath && isGallery) || !videoPath) && (
              <div className={styles.actions}>{actions}</div>
            )}

            <table className={styles.modalDescriptionTable}>
              <tbody>
                <tr>
                  <td className={styles.modalDescriptionTableKey}>Артикул</td>
                  <td>{article}</td>
                </tr>
                {getFormattedData(params).map(([key, val]) => (
                  <tr key={key}>
                    <td className={styles.modalDescriptionTableKey}>{key}</td>
                    <td>{val}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModalDescription;
