import { memo, useCallback } from 'react';
import styles from './styles.module.scss';

const Checkbox = memo((props) => {
  const {
    label, onChange, id, register, name, ...rest
  } = props;

  const handleChange = useCallback((event) => {
    onChange(event.target.checked);
  }, [onChange]);

  const htmlLabel = label?.trim().toLowerCase().split(' ').join('_') || id || name;

  return (
    <label className={styles.checkbox} htmlFor={htmlLabel}>
      <input
        type="checkbox"
        id={htmlLabel}
        name={id || name || ''}
        onChange={handleChange}
        className={styles.input}
        {...rest}
        {...register}
      />

      <span className={styles.checkmark} />

      <span className={styles.label}>{label}</span>
    </label>
  );
});

export default Checkbox;
