import { useState } from 'react'
import Modal from 'components/shared/Modal'
import { ReactComponent as SVGFilter } from 'assets/svg/filter.svg'
import styles from './styles.module.scss'
import Filters from '../Filters';

const modalStyles = {
  top: 0,
  left: 0,
  transform: 'none',
  borderRadius: 0,
  height: '100vh',
  maxHeight: 'initial',
}

const FilterModal = ({ products = [], category, handlePageClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenModal = () => setIsOpen((val) => !val)

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} style={modalStyles}>
        <Filters category={category} products={products} handleOpenModal={handleOpenModal} handlePageClick={handlePageClick} />
      </Modal>

      <button
        className={styles.btnOpenFilter}
        type="button"
        onClick={handleOpenModal}
      >
        <SVGFilter />
        {' '}
        Фильтры
      </button>
    </>
  )
}

export default FilterModal
