import React, { useState, useEffect, useCallback } from 'react'
import useStore from 'hooks/useStore'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  clientfirmsList,
  getClientsByID,
  getShopsByID,
} from 'services/api/v1/orders'
import { ERROR_TOAST_CONFIG } from 'config'
import styles from './styles.module.scss'

const CascadingSelects = ({ control, setValue, errors }) => {
  const [options1, setOptions1] = useState([])
  const [options2, setOptions2] = useState([])
  const [options3, setOptions3] = useState([])

  const [clientfirmOption, setClientfirmOption] = useState(null)
  const [clientOption, setClientOption] = useState(null)
  const [shopOption, setShopOption] = useState(null)

  const [search1, setSearch1] = useState('')
  const [search2, setSearch2] = useState('')
  const [search3, setSearch3] = useState('')

  const { user } = useStore('UserStore')
  const { clientfirmID } = useStore('GlobalStore')
  const { is_stuff } = user

  useEffect(() => {
    setValue('clientfirm_id', clientfirmOption?.value || null)
    setValue('client_id', clientOption?.value || null)
    setValue('shop_id', shopOption?.value || null)
  }, [clientfirmOption, clientOption, shopOption, setValue])

  const fetchOptions1 = useCallback(async () => {
    try {
      const { data } = await clientfirmsList({ search: search1 })

      setOptions1(
        data.clientfirms.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      )
    } catch (error) {
      toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG)
    }
  }, [search1])

  const fetchOptions2 = useCallback(async () => {
    if (clientfirmOption) {
      try {
        const { data } = await getClientsByID(clientfirmOption.value, {
          search: search2,
        })
        setOptions2(
          data.clients.map(({ id, last_name, first_name }) => ({
            value: id,
            label: `${last_name} ${first_name}`,
          })),
        )
      } catch (error) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG)
      }
    }
  }, [search2, clientfirmOption])

  const fetchOptions3 = useCallback(async () => {
    if (clientfirmOption) {
      try {
        const { data } = await getShopsByID(clientfirmOption?.value, {
          search: search3,
        })
        setOptions3(
          data.shops.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        )
      } catch (error) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG)
      }
    }

    if (clientfirmID) {
      try {
        const { data } = await getShopsByID(clientfirmID, {
          search: search3,
        })
        setOptions3(
          data.shops.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        )
      } catch (error) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG)
      }
    }
  }, [clientfirmID, search3, clientfirmOption])

  const resetAfterChangeOrganization = useCallback(() => {
    setClientOption(null)
    setShopOption(null)
    setOptions2([])
    setOptions3([])
    setSearch2('')
    setSearch3('')
  }, [])

  useEffect(() => {
    if (is_stuff) {
      fetchOptions1()
    }
  }, [fetchOptions1, is_stuff])

  useEffect(() => {
    fetchOptions2()
  }, [fetchOptions2])

  useEffect(() => {
    fetchOptions3()
  }, [fetchOptions3])

  return (
    <div className={styles.boxSelects}>
      {is_stuff && (
        <Controller
          name="clientfirm_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <Select
              {...field}
              value={clientfirmOption}
              onChange={(option) => {
                setClientfirmOption(option)
                resetAfterChangeOrganization()
              }}
              options={options1}
              onInputChange={(inputValue) => setSearch1(inputValue)}
              isSearchable
              placeholder="Выберите организацию"
              isClearable
            />
          )}
        />
      )}

      {is_stuff && (
        <Controller
          name="client_id"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <Select
              {...field}
              value={clientOption}
              onChange={(option) => {
                setClientOption(option)
              }}
              options={options2}
              onInputChange={(inputValue) => setSearch2(inputValue)}
              isSearchable
              placeholder="Выберите клиента"
              isDisabled={!clientfirmOption}
              isClearable
            />
          )}
        />
      )}

      <div>
        <Controller
          name="shop_id"
          control={control}
          defaultValue={null}
          rules={{ required: options3.length > 0 }}
          render={({ field }) => (
            <Select
              {...field}
              value={shopOption}
              onChange={(option) => setShopOption(option)}
              options={options3}
              onInputChange={(inputValue) => setSearch3(inputValue)}
              isSearchable
              placeholder="Выберите магазин"
              isDisabled={is_stuff && !clientfirmOption}
              isClearable
            />
          )}
        />
        {errors.shop_id && <p className={styles.error}>Пожалуйста, выберите магазин</p>}
      </div>
    </div>
  )
}

export default CascadingSelects
