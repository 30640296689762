// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
import { downloadOrderData } from 'services/api/v1/orders';

export const downloadOrderDataByID = async (id) => {
  const response = await downloadOrderData(id);

  const contentDisposition = response.headers['content-disposition'];

  const filename = contentDisposition.split('filename=')[1]?.trim().replace(/["']/g, '');

  saveAs(response.data, filename);
};
