import styles from './styles.module.scss';

const PriceInfo = ({ sale, price, oldPrice }) => (
  <div
    className={`${styles.price} ${sale ? styles.salePriceColor : ''}`}
  >
    {`${price} р.`}
    {sale && (
    <span className={styles.oldPrice}>{`${oldPrice} p.`}</span>
    )}
  </div>
);

export default PriceInfo;
