import style from './style.module.scss';

const IconLines = ({ extraStyle }) => (
  <div className={`${style.linesIcon} ${extraStyle}`}>
    <span className={style.line} />
    <span className={style.line} />
    <span className={style.line} />
  </div>
);

export default IconLines;
