import styles from './styles.module.scss';

const Tooltip = ({
  children,
  tooltipText = 'Tooltip Text',
  position = 'Right',
  className = '',
}) => (
  <div className={`${styles.tooltipTrigger} ${className}`}>
    {children}

    <div className={`${styles.tooltip} ${styles[`tooltip${position}`]}`}>{tooltipText}</div>
  </div>
);

export default Tooltip;
