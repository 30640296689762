import { makeAutoObservable } from 'mobx'
import {
  getProfile,
  getFavoritesItems,
  postProfile,
} from 'services/api/v1/users'
import { catalogsCategoriesProductsPartialUpdate } from 'services/api/v1/catalogs'
import { getOrderList, getOrderByID } from 'services/api/v1/orders'

import { toast } from 'react-toastify'
import { ERROR_TOAST_CONFIG } from 'config'

export default class UserStore {
  isLoading = false
  isLoadingOrders = false
  isDataLoading = false
  count = 0
  user = {
    last_name: '',
    first_name: '',
    middle_name: '',
    phone: '',
    photo: '',
    additional_information: '',
    subscribed: null,
    prepay: null,
    is_stuff: null,
    total_orders_sum: null,
  }

  favorite = {}
  ordersList = {}
  singleOrder = {}

  constructor() {
    makeAutoObservable(this)
  }

  getUser = async () => {
    this.isDataLoading = true

    try {
      const { data } = await getProfile();

      this.user = data
    } catch (error) {
      toast('Ошибка при загрузке данных профиля', ERROR_TOAST_CONFIG)
    } finally {
      this.isDataLoading = false
    }
  }

  updateUser = async (param) => {
    try {
      this.isLoading = true

      const { data } = await postProfile(param)

      this.user = data
    } catch (error) {
      toast('Ошибка при обновления данных профиля', ERROR_TOAST_CONFIG)
    } finally {
      this.isLoading = false
    }
  }

  getFavorite = async () => {
    this.isLoading = true

    try {
      const { data } = await getFavoritesItems()

      this.favorite = data

      this.count = data.count
    } catch (error) {
      toast('Ошибка добавления в избранное', ERROR_TOAST_CONFIG)
    } finally {
      this.isLoading = false
    }
  }

  updateFavorite = async (params, data) => {
    await catalogsCategoriesProductsPartialUpdate(params, data)
    this.favorite.results = this.favorite?.results?.filter(
      (item) => item.id !== params.id,
    )
  }

  clearAllFavorites = async () => {
    await Promise.all(
      this.favorite?.results?.map(({ category, id }) => catalogsCategoriesProductsPartialUpdate(
        { url: category.url, id },
        { liked: false },
      )),
    )

    this.favorite.results = []
    this.count = 0
  }

  getOrders = async (params) => {
    this.isLoadingOrders = true

    try {
      const { data } = await getOrderList(params)

      this.ordersList = data
    } catch (error) {
      toast('Ошибка получения списка заказов', ERROR_TOAST_CONFIG)
    } finally {
      this.isLoadingOrders = false
    }
  }

  getNextPageOrders = async () => {
    try {
      if (this.ordersList.next) {
        const { searchParams } = new URL(this.ordersList.next)

        const { data } = await getOrderList({ params: searchParams })

        this.ordersList.results = this.ordersList.results.concat(data.results)

        this.ordersList.next = data.next
      }
    } catch (error) {
      toast('Ошибка при загрузке заказов', ERROR_TOAST_CONFIG)
    }
  }

  getOrderById = async (id) => {
    this.isLoadingOrders = true

    try {
      const { data } = await getOrderByID(id, { params: { offset: 0, limit: 10 } })

      this.singleOrder = data
    } catch (error) {
      toast('Ошибка при загрузке заказа', ERROR_TOAST_CONFIG)
    } finally {
      this.isLoadingOrders = false
    }
  }

  getNextPageOrderById = async (id) => {
    try {
      if (this.singleOrder.next) {
        const { searchParams } = new URL(this.singleOrder.next)

        const { data } = await getOrderByID(id, { params: searchParams })

        this.singleOrder.results = this.singleOrder.results.concat(data.results)

        this.singleOrder.next = data.next
      }
    } catch (error) {
      toast('Ошибка при загрузке заказа', ERROR_TOAST_CONFIG)
    }
  }

  setCount = (val) => {
    this.count = val
  }

  incrementCount = () => {
    this.count += 1
  }

  decrementCount = () => {
    this.count -= 1
  }
}
