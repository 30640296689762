import { makeAutoObservable } from 'mobx';
import { ERROR_TOAST_CONFIG } from 'config';
import { productsSearchGet } from 'services/api/v1/products';
import { toast } from 'react-toastify'
import axios from 'axios';

export default class SearchStore {
  isLoading = false;
  isNextPageLoading = false;
  isNextPageAvailable = true;
  products = {};
  offset = 0;
  limit = 60;
  currentSearchParams = new URLSearchParams();
  currentPage = 0;
  totalCount = 0;
  totalPages = 0;

  cancelTokenSource = null;

  constructor() {
    makeAutoObservable(this);
  }

  updateProducts = (products, append = false) => {
    if (products.next) {
      this.offset += this.limit;
      this.isNextPageAvailable = true;
    } else {
      this.isNextPageAvailable = false;
    }

    if (append) {
      this.products.results = [...this.products.results, ...products.results];
    } else {
      this.products = products;
    }
  };

  requestProducts = async (pageNumber) => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled due to new request.');
    }

    this.cancelTokenSource = axios.CancelToken.source();

    this.products = {}

    try {
      this.isLoading = true
      this.offset = pageNumber ? pageNumber * this.limit : 0;

      const params = new URLSearchParams([...this.currentSearchParams, ['limit', this.limit], ['offset', this.offset]]);

      const { data } = await productsSearchGet({ params, cancelToken: this.cancelTokenSource.token });

      this.updateProducts(data);
      this.totalCount = data.count;
      this.totalPages = Math.ceil(data.count / this.limit);
      this.currentPage = pageNumber || 0;
      this.isLoading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG);
        this.isLoading = false;
      }
    }
  };

  requestNextPage = async () => {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request canceled due to new request.');
    }

    this.cancelTokenSource = axios.CancelToken.source();

    try {
      this.isNextPageLoading = true

      const params = new URLSearchParams([...this.currentSearchParams, ['limit', this.limit], ['offset', this.offset]]);

      const { data } = await productsSearchGet({ params, cancelToken: this.cancelTokenSource.token })
      this.updateProducts(data, true)
      this.currentPage += 1;
      this.isNextPageLoading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast('Ошибка при загрузке данных', ERROR_TOAST_CONFIG);
        this.isNextPageLoading = false;
      }
    }
  };

  setCurrentSearchParams = (newSearchParams) => {
    if (newSearchParams) {
      this.currentSearchParams = newSearchParams;
    }
  }
}
