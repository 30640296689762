import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import Image from 'components/shared/Image'
import { ReactComponent as Phone } from 'assets/svg/phone.svg'
import { ReactComponent as Telegram } from 'assets/svg/telegram.svg'
import { ReactComponent as Mail } from 'assets/svg/mail.svg'
import { ReactComponent as Viber } from 'assets/svg/viber.svg'
import { ReactComponent as WhatsApp } from 'assets/svg/whatsapp.svg'
import ContactLink from './ContactLink'

import style from './style.module.scss'

const regex = /[\s-]+/g
const formattedNumber = (val) => val?.replace(regex, '')

const ManagerCard = ({ children, isShow, position = '' }) => {
  const { breakpoint, manager } = useStore('GlobalStore')
  const { user } = useStore('UserStore')

  const managerName = `${manager?.first_name || 'Имя'} ${
    manager?.last_name || 'Менеджера'
  } ${manager?.middle_name || ''}`

  const isDesktop = breakpoint === 'desktop'

  const triggerButton = () => {
    if (children) {
      return children
    }

    return (
      <div className={style.managerNameWrapper}>
        <span className={style.managerNameWrapperPrev}>Ваш менеджер:</span>
        <span className={style.managerName}>{managerName}</span>
        <span className={style.managerNameWrapperSvg}>
          <svg
            width="9"
            height="5"
            viewBox="0 0 9 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.30859 4.69141L1.13672 1.46484C0.972656 1.31901 0.972656 1.16406 1.13672 1L1.32812 0.808594C1.47396 0.644531 1.62891 0.644531 1.79297 0.808594L4.52734 3.59766L7.26172 0.808594C7.42578 0.66276 7.58073 0.66276 7.72656 0.808594L7.91797 1C8.08203 1.16406 8.08203 1.31901 7.91797 1.46484L4.74609 4.69141C4.60026 4.83724 4.45443 4.83724 4.30859 4.69141Z"
              fill="#4F4F4F"
            />
          </svg>
        </span>
      </div>
    )
  }

  if (user?.is_stuff) {
    return null
  }

  return (
    (isShow || isDesktop) && (
      <div className={style.manager}>
        {triggerButton()}

        <div
          className={`${style.managerCard} ${position ? style[position] : ''}`}
        >
          <div className={style.managerImage}>
            <Image src={manager?.photo} alt="Manager photo" />
          </div>

          <div className={style.managerInfo}>
            <button
              type="button"
              className={style.managerName}
            >
              {managerName}
              <span>
                <svg
                  width="9"
                  height="5"
                  viewBox="0 0 9 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.30859 4.69141L1.13672 1.46484C0.972656 1.31901 0.972656 1.16406 1.13672 1L1.32812 0.808594C1.47396 0.644531 1.62891 0.644531 1.79297 0.808594L4.52734 3.59766L7.26172 0.808594C7.42578 0.66276 7.58073 0.66276 7.72656 0.808594L7.91797 1C8.08203 1.16406 8.08203 1.31901 7.91797 1.46484L4.74609 4.69141C4.60026 4.83724 4.45443 4.83724 4.30859 4.69141Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </span>
            </button>

            <div className={`${style.wrapperInfo}`}>
              <ContactLink
                href={`tel:${formattedNumber(manager?.phone)}`}
                icon={<Phone className={`${style.iconManagerCard}`} />}
                text={manager?.phone}
              />

              <ContactLink
                href={`tel:${formattedNumber(manager?.phone)}`}
                icon={(
                  <>
                    {
                      manager?.telegram && (
                        <Telegram className={`${style.iconManagerCard}`} />
                      )
                    }

                    {
                      manager?.viber && (
                        <Viber className={`${style.iconManagerCard}`} />
                      )
                    }

                    {
                      manager?.whatsapp && (
                        <WhatsApp className={`${style.iconManagerCard}`} />
                      )
                    }
                  </>
                )}
                text={manager?.phone}
              />

              <ContactLink
                href={`mailto:${manager?.email}`}
                icon={<Mail className={`${style.iconManagerCard}`} />}
                text={manager?.email}
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default observer(ManagerCard)
