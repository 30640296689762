import { useState } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { routeLinks } from 'config/routeLinks'

import { ReactComponent as ArrowSWG } from 'assets/svg/arrow-round.svg'
import { ReactComponent as Closer } from 'assets/svg/closer.svg'
import style from './style.module.scss'

const SearchInput = () => {
  const [, setSearch] = useSearchParams()
  const location = useLocation()
  const [value, setValue] = useState('')
  const navigate = useNavigate()

  const handleSand = (event) => {
    event.preventDefault()

    if (value === '') {
      return
    }

    if (location.pathname !== routeLinks.productsSearch) {
      navigate(`${routeLinks.productsSearch}?search=${value}`)
    } else {
      setSearch({ search: value })
    }
  }

  const clearInput = () => {
    setValue('')
  }

  return (
    <form action="#" className={style.headerSearch}>
      <input
        className={`${style.searchInput} ${value ? style.rightPadding : ''}`}
        value={value}
        type="text"
        placeholder="Поиск по названию и артикулу товара"
        name="main_search_input"
        onChange={(event) => setValue(event.target.value)}
      />

      <div className={style.actionBar}>
        <button type="submit" className={style.btnSearch} onClick={handleSand}>
          <ArrowSWG className={style.searchGoItem} />
        </button>

        {value && (
          <button
            type="button"
            className={style.clearBtn}
            onClick={() => clearInput()}
          >
            <Closer />
          </button>
        )}
      </div>
    </form>
  )
}

export default SearchInput
