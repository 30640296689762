import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import useStore from 'hooks/useStore'
import { postCreateNewOrder } from 'services/api/v1/orders'
import { useNavigate } from 'react-router-dom'
import { routeLinks } from 'config/routeLinks'
import CascadingSelects from 'components/shared/Select/CascadingSelects'
import Modal from 'components/shared/Modal'
import Button from 'components/shared/Button'
import styles from './styles.module.scss'

const Invoices = ({
  totalVolume,
  totalSum,
  totalDiscount,
  isPrepay,
  isTopCartInfo = false,
}) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const { setCartCount, setCartSum } = useStore('CartStore')
  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm()

  const handleDoOrder = async (params) => {
    try {
      await postCreateNewOrder(params)

      setCartCount(0)
      setCartSum(0)

      const message = 'Спасибо! Ваш заказ отправлен! Заказ можно посмотреть в разделе История заказов в Личном кабинете'

      toast(message, {
        position: 'top-right',
        autoClose: 2800,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 1,
        theme: 'colored',
      })

      setTimeout(() => {
        navigate(routeLinks.orders)
      }, 3000)
    } catch (error) {
      const { data } = error.response

      toast(data?.client_id || data?.store_id || data?.msg, {
        position: 'top-right',
        autoClose: 2500,
        type: toast.TYPE.ERROR,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    }
  }

  const handleReadyToOrder = () => setIsOpen(true)

  return (
    <>
      <div
        className={`${styles.execution} ${
          isTopCartInfo ? styles.isTopCartInfo : ''
        }`}
      >
        <div className={styles.executionInfo}>
          <div>
            {'Выбран способ оплаты: '}
            <span>{isPrepay ? 'Предоплата' : 'Отсрочка'}</span>
          </div>

          <div>
            Итого к оплате (с НДС):
            <span>{`${totalSum}р`}</span>
          </div>

          <div className={styles.totalDiscount}>
            Общая скидка:
            <span>{`${totalDiscount}р`}</span>
          </div>

          <div className={styles.volume}>
            Общий объем заказа:
            <span>{`${totalVolume}м3`}</span>
          </div>
        </div>

        <div className={styles.executionDocuments}>
          <button
            className={styles.primary}
            onClick={handleReadyToOrder}
            type="button"
          >
            Перейти к оформлению
          </button>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size="auto"
        hideCloser
        style={{ width: '80vw', minHeight: '60vh' }}
      >
        <form
          action="#"
          onSubmit={handleSubmit(handleDoOrder)}
          className={styles.form}
        >
          <h2 className={styles.modalTitle}>
            Вы можете оставить комментарий к заказу.
          </h2>

          <CascadingSelects control={control} setValue={setValue} errors={errors} />

          <div>
            <textarea
              {...register('comment')}
              rows={6}
              maxLength={500}
              className={styles.modalComment}
              placeholder="Комментарий"
            />

            <p className={styles.modalText}>
              После оформления заказы вы будете автоматически перенаправленны к
              списку заказов
            </p>
          </div>

          <div className={styles.modalButtons}>
            <Button type="submit">Сделать заказ</Button>

            <Button onClick={() => setIsOpen(false)}>Отмена</Button>
          </div>
        </form>
      </Modal>

      <ToastContainer />
    </>
  )
}

export default Invoices
