import React from 'react'
import { ReactComponent as Repeat } from 'assets/svg/repeat.svg'
import { ReactComponent as Download } from 'assets/svg/download.svg'
import { ReactComponent as Info } from 'assets/svg/info.svg'
import { downloadOrderDataByID } from 'helpers/downloadOrderDataByID'
import styles from './styles.module.scss'

const renderBoldText = (text) => <span className={styles.bold}>{text}</span>

const renderDateAndTime = (val) => {
  const [date] = val.split('T')
  return (
    <div className={styles.dateAndTime}>
      <p>{date}</p>
    </div>
  )
}

const renderPrepay = (prepay) => (
  <span className={`${styles.prepay} ${prepay ? styles.isPrepay : ''}`}>
    {prepay ? 'Предоплата' : 'Отсрочка'}
  </span>
)

const renderActionButton = (onClick, icon, label) => (
  <button type="button" onClick={onClick}>
    {icon}
    {label}
  </button>
)

export const columns = ({
  handleRepeatOrder,
  handleShowMore,
  handleShowList,
  isDesktop,
  isManager,
}) => {
  const baseColumns = [
    {
      title: '№ заказа',
      dataIndex: 'number',
      render: (val) => renderBoldText(val),
      align: 'center',
    },
    {
      title: 'Дата и время',
      dataIndex: 'created_at',
      render: (val) => renderDateAndTime(val),
      align: 'center',
    },
    {
      title: 'Магазин',
      dataIndex: 'shop',
      render: (val) => val || '-',
      align: 'center',
    },
    {
      title: 'Количество товаров',
      dataIndex: 'total_count',
      align: 'center',
    },
    {
      title: 'Сумма',
      dataIndex: 'total_sum',
      render: (val) => renderBoldText(val),
      align: 'center',
    },
    {
      title: 'Объём',
      dataIndex: 'total_volume',
      align: 'center',
    },
    {
      title: 'Предоплата',
      dataIndex: 'prepay',
      align: 'center',
      render: (prepay) => renderPrepay(prepay),
    },
    {
      title: 'Действия',
      align: 'left',
      children: [
        {
          render: ({ id, total_count }) => renderActionButton(
            () => handleRepeatOrder({ id, total_count }),
            <Repeat />,
            isDesktop ? 'Повторить' : null,
          ),
        },
        {
          render: ({ id }) => renderActionButton(
            () => downloadOrderDataByID(id),
            <Download />,
            isDesktop ? 'Скачать XLSX' : null,
          ),
        },
        {
          render: ({ id }) => renderActionButton(
            () => {
              handleShowMore(id)
              handleShowList()
            },
            <Info />,
            isDesktop ? 'Подробно' : null,
          ),
        },
      ],
    },
  ]

  return isManager
    ? baseColumns
    : baseColumns.slice(0, 5).concat(baseColumns.slice(5))
}

export const customFilterOption = [
  {
    value: '-created_at',
    label: 'Только новые',
  },
  {
    value: 'created_at',
    label: 'Только старые',
  },
  {
    value: 'sum',
    label: 'Только дешевые',
  },
  {
    value: '-sum',
    label: 'Только дорогие',
  },
]
