import { useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { logoutUser } from 'services/auth';
import { routeLinks } from 'config/routeLinks';
import useStore from 'hooks/useStore';
import { menu } from './config';
import styles from './styles.module.scss';

const ProfileMenu = () => {
  const { user } = useStore('UserStore');
  const isStuff = user?.is_stuff
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogOut = useCallback(async () => {
    try {
      await logoutUser();
      navigate(routeLinks.login);
    } catch (error) {
      navigate(routeLinks.notFound);
    }
  }, [navigate]);

  return (
    <ul className={styles.accauntMenu}>
      {menu
        .filter(({ isForClientOnly }) => !isForClientOnly || (isForClientOnly && !isStuff))
        .map(({ path, title }) => (
          <li
            key={`${path}_${title}`}
            className={pathname === path ? styles.active : ''}
          >
            <Link to={path}>{title}</Link>
          </li>
        ))}

      <li>
        <button type="button" onClick={() => handleLogOut()}>
          Выйти
        </button>
      </li>
    </ul>
  );
};

export default ProfileMenu;
