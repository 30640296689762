import { useEffect } from 'react';

export const useBodyLock = (isLock) => {
  useEffect(() => {
    if (isLock) {
      document.body.classList.add('lock');
    } else {
      document.body.classList.remove('lock');
    }
  }, [isLock]);
};
