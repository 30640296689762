import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { findPathByIdRecursive } from 'helpers/findPathByIdRecursive'
import useStore from 'hooks/useStore'
import PageNotFound from 'routes/PageNotFound'
import Container from 'components/shared/Container'
import SideMenu from 'components/SideMenu'
import Filters from 'components/Filters'
import Title from 'components/shared/Title'
import Loader from 'components/shared/Loader'
import FiltersByMarker from 'components/FiltersByMarker'
import Breadcrumbs from 'components/Breadcrumbs'
import Image from 'components/shared/Image'
import ProductList from './ProductList'

import styles from './styles.module.scss'

const ProductListContainer = () => {
  const {
    dictionaries,
    products,
    isLoadCriticalData,
    breakpoint,
    sideMenuTree,
    requestNextPage,
    isNextPageAvailable,
    isLoading, isNextPageLoading, totalPages, requestProducts, setCurrentSearchParams, currentPage,
  } = useStore('GlobalStore');
  const { user } = useStore('UserStore');
  const { prepay } = user;
  const [searchParams, setSearchParams] = useSearchParams();
  const { '*': nestedPath } = useParams();

  const [title, setTitle] = useState('')

  const isDesktop = breakpoint === 'desktop'
  const nestedPathArray = nestedPath.split('/').filter((part) => part !== '')
  const categorySlug = nestedPathArray[nestedPathArray.length - 1];

  const [catalogId, categoryId] = useMemo(
    () => findPathByIdRecursive(dictionaries, categorySlug),
    [categorySlug, dictionaries],
  )

  const findCatalog = useCallback((tree, id) => {
    const fistIteration = tree.find((item) => item.id === id)
    if (fistIteration) {
      return fistIteration;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const item of tree) {
      if (item.child && item.child.length > 0) {
        const found = findCatalog(item.child, id);

        if (found) {
          return found;
        }
      } else if (item.id === id) {
        return item;
      }
    }

    return null;
  }, []);

  const currentCategoryChild = useMemo(
    () => findCatalog(sideMenuTree, categoryId?.id)?.child,
    [categoryId?.id, findCatalog, sideMenuTree],
  )

  const handleGetProducts = useCallback(() => {
    if (catalogId && categoryId) {
      setTitle(categoryId?.title)

      const updatedSearchParams = new URLSearchParams(searchParams.toString());

      setCurrentSearchParams(updatedSearchParams);
      setSearchParams(updatedSearchParams);

      requestProducts({
        params: {
          catalogId: catalogId?.id,
          categoryId: categoryId?.id,
        },

      });
    }
  }, [catalogId, categoryId, requestProducts, searchParams, setCurrentSearchParams, setSearchParams, prepay]);

  useEffect(() => {
    handleGetProducts()
  }, [handleGetProducts])

  if (!isLoadCriticalData) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader isLoading={!isLoadCriticalData} />
      </div>
    )
  }

  if (!catalogId && !categoryId) {
    return <PageNotFound />
  }

  const handlePageClick = ({ selected }) => {
    requestProducts({
      params: {
        catalogId: catalogId?.id,
        categoryId: categoryId?.id,
      },
      selected,
    });
  };

  const showMoreProducts = () => {
    requestNextPage({ params: {
      catalogId: catalogId?.id,
      categoryId: categoryId?.id,
    } });
  };

  return (
    <>
      <Breadcrumbs />

      <Container>
        <Title extra={isDesktop ? <FiltersByMarker /> : null}>{title}</Title>

        {currentCategoryChild && (
        <div className={styles.gridSubCategory}>
          {
                            currentCategoryChild.map((item) => (
                              <Link
                                key={item.slug}
                                to={`${nestedPath}${item.slug}/`}
                                className={styles.subCategoryCard}
                              >
                                <div className={styles.subCategoryCardImage}>
                                  <Image src={item.image} alt={item.title} />
                                </div>
                                <div className={styles.subCategoryCardTitle}>{item.title}</div>
                              </Link>
                            ))
                        }
        </div>
        )}

        <div className={styles.wrapper}>
          <div className={styles.filtersWrapper}>
            <SideMenu />

            {isDesktop ? <Filters category={categorySlug} products={products} handlePageClick={handlePageClick} /> : null}
          </div>

          {!isDesktop ? <FiltersByMarker /> : null}
          <ProductList
            category={categorySlug}
            isLoadingProducts={isLoading}
            isNextPageLoading={isNextPageLoading}
            products={products}
            isNextPageAvailable={isNextPageAvailable}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            showMoreProducts={showMoreProducts}
            totalPages={totalPages}
          />
        </div>
      </Container>
    </>
  )
}

export default observer(ProductListContainer)
