import { Routes, Route } from 'react-router-dom';
import { routes } from 'config';
import PrivateRoute from 'routes/PrivateRoute';
import PageNotFound from 'routes/PageNotFound';
import Header from 'components/Header';
import Footer from 'components/Footer';
// MAIN
import LoginPage from 'routes/LoginPage';
import ResetPasswordPage from 'routes/PasswordReset';
import ResetPasswordConfirmPage from 'routes/PasswordResetConfirm';
import MainPage from 'routes/MainPage';
import CatalogPage from 'routes/CatalogPage';
import ProductsPage from 'routes/ProductsPage';
import CartPage from 'routes/CartPage';
import SearchPage from 'routes/SearchPage';
// PROFILE
import ProfilePage from 'routes/ProfilePage';
import FavoritesPage from 'routes/FavoritesPage';
import OrdersPage from 'routes/OrdersPage';
import OrderedPage from 'routes/OrderedPage';
import SiteFeedback from 'routes/SiteFeedback';

import Products from './Products';
import MultiCatalogsPage from './MultiCatalogsPage';

const RootRouter = () => (
  <Routes>
    {/* public */}
    <Route exact path={routes.login()} element={<LoginPage />} />

    <Route
      exact
      path={routes.passwordReset()}
      element={<ResetPasswordPage />}
    />

    <Route
      exact
      path={routes.passwordResetConfirm()}
      element={<ResetPasswordConfirmPage />}
    />

    {/* private */}
    <Route element={<PrivateRoute />}>
      <Route exact path={routes.main()} element={<MainPage />} />

      <Route exact path={routes.cart()} element={<CartPage />} />
      <Route exact path={routes.profile()} element={<ProfilePage />} />
      <Route exact path={routes.favorites()} element={<FavoritesPage />} />
      <Route exact path={routes.orders()} element={<OrdersPage />} />
      <Route exact path={routes.siteFeedback()} element={<SiteFeedback />} />
      <Route exact path={routes.ordered()} element={<OrderedPage />} />

      {/* parent products disable */}
      <Route exact path={routes.products()} element={<PageNotFound />} />

      <Route path={routes.products()}>
        <Route exact path="new/" element={<Products />} />
        <Route exact path="popular/" element={<Products />} />
        <Route exact path="sale/" element={<Products />} />
        <Route exact path="search/" element={<SearchPage />} />

        <Route path=":catalogSlug/" element={<CatalogPage />} />

        <Route path=":catalogSlug/*" element={<ProductsPage />} />
      </Route>

      {/* parent products disable */}
      <Route exact path={routes.categories()} element={<PageNotFound />} />

      <Route path={routes.categories()}>
        <Route path=":catalogSlug/" element={<MultiCatalogsPage />} />
        <Route path=":catalogSlug/*" element={<ProductsPage />} />
      </Route>
    </Route>

    {/* 👇️ only match this when no other routes match */}
    <Route
      exact
      path={routes.notFound()}
      element={(
        <div className="main-wrapper">
          <Header />

          <main>
            <PageNotFound />
          </main>

          <Footer />
        </div>
      )}
    />
  </Routes>
);

export default RootRouter;
