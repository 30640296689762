import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import { routeLinks } from 'config/routeLinks'
import { postRepeatOrderByID } from 'services/api/v1/orders'
import ProductListTop from 'modules/FilterOrdering'
import Loader from 'components/shared/Loader'
import Modal from 'components/shared/Modal'
import Button from 'components/shared/Button'
import CurrentOrderTable from './CurrentOrderTable'
import OrdersMainTable from './OrdersMainTable'
import { columns, customFilterOption } from './config'
import styles from './styles.module.scss'

const Orders = () => {
  const {
    getOrders,
    getOrderById,
    ordersList,
    singleOrder,
    user,
    isLoadingOrders,
  } = useStore('UserStore')
  const [showSingleOrder, setShowSingleOrder] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [singleOrderID, setSingleOrderID] = useState(null)

  const { updateCartCount } = useStore('CartStore')
  const { breakpoint } = useStore('GlobalStore')

  const [searchParams] = useSearchParams({ offset: 0, limit: 10 })

  const isDesktop = breakpoint === 'desktop'
  const isManager = user?.is_stuff

  const navigate = useNavigate()

  useEffect(() => {
    let ready = true

    if (ready) {
      getOrders({ params: searchParams })
    }

    return () => {
      ready = false
    }
  }, [getOrders, searchParams])

  const handleRepeatOrder = async (param) => {
    try {
      await postRepeatOrderByID(param)

      setIsOpen(true)

      updateCartCount(param.total_count)
    } catch (error) {
      console.log(error)
    }
  }

  const handleShowMore = (id) => {
    setSingleOrderID(id)
    getOrderById(id)
  }

  const handleShowList = () => setShowSingleOrder(!showSingleOrder)

  return (
    <div className={styles.tableWrapper}>
      {showSingleOrder && <ProductListTop customOption={customFilterOption} />}

      <Loader isLoading={isLoadingOrders} className={styles.loaderBox}>
        {showSingleOrder ? (
          <OrdersMainTable
            ordersList={ordersList}
            columns={columns({
              handleRepeatOrder,
              handleShowMore,
              handleShowList,
              isDesktop,
              isManager,
            })}
          />
        ) : (
          <CurrentOrderTable
            handleShowList={handleShowList}
            handleRepeatOrder={handleRepeatOrder}
            data={singleOrder}
            orderID={singleOrderID}
          />
        )}
      </Loader>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="auto">
        <div className={styles.message}>
          Товары были добавлены в Корзину.
          <div className={styles.question}>Хотите перейти к корзине?</div>
        </div>

        <Button maxWidth onClick={() => navigate(routeLinks.cart)}>
          Перейти к корзине
        </Button>
      </Modal>
    </div>
  )
}

export default observer(Orders)
