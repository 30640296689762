import instance from 'services/request'

export const getOrderList = (params) => instance.get('/v1/orders/', params)

export const getShopList = (params) => instance.get('/v1/shops/', params)

export const getOrderByID = (id, params) => instance.get(`/v1/orderlines/${id}/`, params)

export const postRepeatOrderByID = ({ id }) => instance.post(`/v1/orders/${id}/repeat/`)

export const postCreateNewOrder = (params) => instance.post('/v1/orders/create_order/', params)

export const downloadOrderData = (id) => instance.get(`/v1/orders/${id}/download/`, {
  responseType: 'blob',
})

export const clientfirmsList = (params) => instance.get('/v1/clientfirms/', { params })

export const getClientsByID = (id, params) => instance.get(`/v1/${id}/clients/`, { params })

export const getShopsByID = (id, params) => instance.get(`/v1/${id}/shops/`, { params })
