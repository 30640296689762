import Filters from 'components/Filters';
import Container from 'components/shared/Container';
import Title from 'components/shared/Title';
import useStore from 'hooks/useStore';
import ProductList from 'modules/Products/ProductList';
import { useSearchParams } from 'react-router-dom';

import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import SideMenu from 'components/SideMenu';
import styles from './styles.module.scss';

const OrderedPage = () => {
  const {
    requestProducts,
    requestNextPage,
    products,
    isLoading,
    isNextPageLoading,
    sideMenuTree,
    setCurrentSearchParams,
    isNextPageAvailable,
    currentPage,
    totalPages,
  } = useStore('ProductsStore');
  const { breakpoint } = useStore('GlobalStore');
  const [searchParams, setSearchParams] = useSearchParams()

  const isDesktop = breakpoint === 'desktop';

  const handleSearchParams = useCallback(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('ordered', 1);

    setCurrentSearchParams(updatedSearchParams)
    setSearchParams(updatedSearchParams);
    requestProducts()
  }, [requestProducts, searchParams, setCurrentSearchParams, setSearchParams])

  const handlePageClick = ({ selected }) => {
    requestProducts(selected);
  };

  const showMoreProducts = () => {
    requestNextPage();
  }

  useEffect(() => {
    handleSearchParams()
  }, [handleSearchParams])

  return (
    <Container>
      <Title>Ранее заказывали</Title>

      <div className={styles.wrapper}>
        <div className={styles.filtersWrapper}>
          <SideMenu tree={sideMenuTree} />
          {isDesktop ? <Filters brandsList={products.brands} /> : null}
        </div>

        <ProductList
          isLoadingProducts={isLoading}
          isNextPageLoading={isNextPageLoading}
          products={products}
          isNextPageAvailable={isNextPageAvailable}
          handlePageClick={handlePageClick}
          currentPage={currentPage}
          showMoreProducts={showMoreProducts}
          totalPages={totalPages}
        />
      </div>
    </Container>
  );
};

export default observer(OrderedPage);
