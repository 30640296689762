import Products from 'modules/Products';
import ScrollToTop from 'components/ScrollToTop';

const ProductsPage = () => (
  <ScrollToTop>
    <Products />
  </ScrollToTop>
);

export default ProductsPage;
