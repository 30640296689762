import styles from './styles.module.scss'

const ViewButton = ({ view, selectedView, handleSelectView, svgComponent }) => (
  <button
    className={`${styles.viewItem} ${
      selectedView === view ? styles.active : ''
    }`}
    type="button"
    onClick={() => handleSelectView(view)}
  >
    {svgComponent}
  </button>
)

export default ViewButton
