import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Input from 'components/shared/Input'
import Textarea from 'components/shared/Textarea'
import Button from 'components/shared/Button'
import ProfileTemplate from 'components/templates/ProfileTemplate'
import { ToastContainer, toast } from 'react-toastify'
// eslint-disable-next-line import/no-extraneous-dependencies
import Dropzone from 'react-dropzone'
import { ReactComponent as Closer } from 'assets/svg/closer.svg'
import { postSiteFeedback } from 'services/api/v1/users'
import { ERROR_TOAST_CONFIG, SUCCESS_TOAST_CONFIG } from 'config'
import styles from './styles.module.scss'

const SiteFeedbackForm = () => {
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm()
  const [files, setFiles] = useState([])

  const onSend = async (formData) => {
    try {
      const data = new FormData()
      data.append('title', formData.title)
      data.append('text', formData.text)
      files.forEach((file) => {
        data.append('attachments', file);
      });

      await postSiteFeedback(data);

      toast.success('Ваш отзыв был отправлен!', SUCCESS_TOAST_CONFIG);

      reset();
      setFiles([]);
    } catch (error) {
      const err = Object.entries(error.response.data)

      err.forEach(([key, val]) => {
        setError(key, { type: 'server', message: val })
      })

      if (error.response.status === 413) {
        toast.error('Ошибка. Размер документа слишком большой.', ERROR_TOAST_CONFIG);
      } else {
        toast.error('Что-то пошло не так, проверьте поля формы.', ERROR_TOAST_CONFIG);
      }

      setFiles([]);
    }
  }

  const handleFileDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles])
  }

  const handleDeleteFile = (index) => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)
  }

  return (
    <ProfileTemplate>
      <div className="container">
        <form onSubmit={handleSubmit(onSend)} className={styles.form}>
          <Input
            label="Тема"
            register={register}
            required
            name="title"
            isError={errors.subject}
            errorMessage={errors.subject?.message}
          />

          <Textarea
            id="message"
            name="text"
            label="Напишите текст сообщения!"
            register={register}
            required
            isText
          />

          <Dropzone onDrop={handleFileDrop}>
            {({ getRootProps, getInputProps }) => (
              <section className={styles.uploadFileWrapper}>
                <div className={styles.uploadFile} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Перетащите сюда файл(ы) или нажмите, чтобы выбрать.</p>
                </div>
              </section>
            )}
          </Dropzone>

          <div className={styles.fileList}>
            {files.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles.fileItem}>
                <span>{file.name}</span>
                <button type="button" onClick={() => handleDeleteFile(index)}>
                  <Closer />
                </button>
              </div>
            ))}
          </div>

          <div className={styles.submitButtonWrapper}>
            <Button type="submit" colorSchema="blue">Отправить отзыв</Button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </ProfileTemplate>
  )
}

export default observer(SiteFeedbackForm)
