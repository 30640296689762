import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-round.svg';

import Image from 'components/shared/Image';

import styles from './styles.module.scss';

const Card = (props) => {
  const {
    image, name, slug, isSubCatalogCard = false, preview,
  } = props;

  return (
    <Link
      to={`${slug}/`}
      className={`${styles.catalogItem} ${isSubCatalogCard ? styles.subCatalog : ''}`}
    >
      <div className={`${styles.imageBox} ${image}`}>
        <div>
          <Image src={preview || image} alt={name} />
        </div>
      </div>

      <div className={`${styles.itemName} ${isSubCatalogCard ? styles.center : ''}`}>
        <span className={styles.name}>{name}</span>
        {
          !isSubCatalogCard && (
            <ArrowIcon
              className={styles.icon}
            />
          )
        }
      </div>
    </Link>
  );
};

export default Card;
