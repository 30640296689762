import { Link } from 'react-router-dom';
import { scrollToTop } from 'helpers/scrollToTop';
import style from './style.module.scss';

const Item = (props) => {
  const {
    linkTo, icon, isCounter, counterData, text, customCounter, isNotLink,
  } = props;

  if (isNotLink) {
    return (
      <div className={style.menuItem}>
        <div className={style.menuItemBox}>
          {icon}

          {isCounter && <span className={`${style.counter}`}>{counterData}</span>}

          {customCounter}
        </div>

        <p>{text}</p>
      </div>
    );
  }

  return (
    <Link to={linkTo} onClick={() => scrollToTop()} className={style.menuItem}>
      <div className={style.menuItemBox}>
        {icon}

        {isCounter && <span className={`${style.counter}`}>{counterData}</span>}

        {customCounter}
      </div>

      <p>{text}</p>
    </Link>
  );
};

export default Item;
