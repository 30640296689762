import { useState, useId } from 'react';
import { ReactComponent as EyeSlash } from 'assets/svg/eye.svg';
import { ReactComponent as Eye } from 'assets/svg/eye-open.svg';
import styles from './styles.module.scss';

const Input = ({
  label,
  type = 'text',
  name,
  register,
  required,
  isError,
  errorMessage,
}) => {
  const inputId = useId();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className={styles.inputContainer}>
      <label
        htmlFor={`${inputId}-${name}`}
        className={styles.label}
      >
        {label}
        :
      </label>

      <div className={styles.inputWithIcon}>
        <input
          id={`${inputId}-${name}`}
          name={name}
          type={showPassword ? 'text' : type}
          {...register(name, { required })}
          className={`${styles.input} ${isError ? styles.error : ''}`}
        />

        {/* Кнопка для показа/скрытия пароля */}
        {type === 'password' && (
          <button
            type="button"
            className={styles.togglePasswordBtn}
            onClick={togglePasswordVisibility}
            aria-label={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
          >
            {showPassword ? <EyeSlash /> : <Eye />}
          </button>
        )}
      </div>

      {isError && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
};

export default Input;
