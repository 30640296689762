import React, { useState, useEffect } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-filter-menu.svg';

import styles from './styles.module.scss';

const goToTop = () => window.scrollTo({ top: 0 });

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!showTopBtn) {
    return null;
  }

  return (
    <div className={styles.btnToTop}>
      <ArrowIcon className={styles.icon} onClick={goToTop} />
    </div>
  );
};

export default ScrollToTop;
