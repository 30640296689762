import LoginTemplate from 'components/templates/LoginTemplate';
import PasswordResetForm from 'modules/PasswordResetForm';

const ResetPasswordPage = () => (
  <LoginTemplate>
    <PasswordResetForm />
  </LoginTemplate>
);

export default ResetPasswordPage;
