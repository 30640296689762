import { useState } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as Heart } from 'assets/svg/heart.svg';
import useStore from 'hooks/useStore';

import styles from './styles.module.scss';

const Like = (props) => {
  const {
    isSelected = false, categoryUrl, id,
  } = props;
  const [selected, setSelected] = useState(isSelected);
  const { incrementCount, decrementCount, updateFavorite } = useStore('UserStore');

  const handleSelect = () => {
    setSelected(!selected);
    updateFavorite({ url: categoryUrl, id }, { liked: selected });

    return selected ? decrementCount() : incrementCount();
  };

  return (
    <button
      type="button"
      className={`${styles.like} ${selected ? styles.selected : ''}`}
      onClick={handleSelect}
    >
      <Heart />
    </button>
  );
};

export default observer(Like);
