/**
 *
 * @param {URLSearchParams} URLSearchParams
 * @param {arr} excludedFields
 * @returns {arr}
 */
export const excludeSearchParams = (URLSearchParams, excludedFields) => {
  const arrayFromObj = Array.from(URLSearchParams);
  return arrayFromObj.filter(([key]) => !excludedFields.includes(key));
};
