import LoginTemplate from 'components/templates/LoginTemplate';
import Login from 'modules/Login';

const LoginPage = () => (
  <LoginTemplate>
    <Login />
  </LoginTemplate>
);

export default LoginPage;
