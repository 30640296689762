import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import Card from 'components/Card';
import Loader from 'components/shared/Loader';
import styles from './styles.module.scss';

const Catalogs = () => {
  const { dictionaries, isLoadCriticalData } = useStore('GlobalStore');

  if (!isLoadCriticalData) {
    return (
      <div className={styles.loadingWrapper}>
        <Loader isLoading={!isLoadCriticalData} />
      </div>
    );
  }

  return (
    <div className={styles.list}>
      {dictionaries?.results?.map((item) => (
        <Card
          key={item.id}
          image={item.image}
          preview={item.preview}
          name={item.title}
          slug={`/products/${item.slug}`}
          catalogId={item.id}
        />
      ))}
    </div>
  );
};

export default observer(Catalogs);
