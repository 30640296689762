import useStore from 'hooks/useStore';
import ProfileTemplate from 'components/templates/ProfileTemplate';
import FavoritesContainer from 'modules/FavoritesContainer';
import styles from './styles.module.scss';

const FavoritesPage = () => {
  const { clearAllFavorites } = useStore('UserStore');

  return (
    <ProfileTemplate
      title="Личный кабинет"
      extra={(
        <button
          className={styles.clearAll}
          type="button"
          onClick={() => clearAllFavorites()}
        >
          Очистить избранное
        </button>
      )}
    >
      <FavoritesContainer />
    </ProfileTemplate>
  );
};

export default FavoritesPage;
