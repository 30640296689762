import styles from './styles.module.scss'

const Video = ({ videoPath }) => (
  <iframe
    className={styles.video}
    width="100%"
    height="450"
    src={videoPath}
    title="Video player"
    allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
    frameBorder="0"
    scrolling="no"
    allowFullScreen
  />
)

export default Video
