import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as Arrow } from 'assets/svg/arrow-filter-menu.svg';
import styles from './styles.module.scss';
import { getQueryParamString, handleFindCategorySlug, getPathIDsBySlug } from './utils';

const SideMenuItemsList = ({ tree, categorySlug, originalCategories, showAll, countVisibleElements }) => {
  const [expandedCategoryIds, setExpandedCategoryIds] = useState([]);
  const [searchParams] = useSearchParams();

  const linkPath = useCallback((category) => {
    const url = `/products/${handleFindCategorySlug(originalCategories?.results, category.id)}/${category.slug}/`.replace(/\/\//g, '/');

    const searchP = getQueryParamString(category) || `?${searchParams.toString()}`;
    return url + searchP;
  }, [originalCategories?.results, searchParams]);

  const handleExpandedCategoryIds = useCallback((slug) => {
    const arrayOfIDs = getPathIDsBySlug(tree, slug);

    if (expandedCategoryIds.includes(arrayOfIDs[arrayOfIDs.length - 1])) {
      setExpandedCategoryIds((prevState) => prevState.filter((item) => item !== arrayOfIDs[arrayOfIDs.length - 1]));
    } else {
      setExpandedCategoryIds(arrayOfIDs);
    }
  }, [tree, expandedCategoryIds]);

  useEffect(() => {
    setExpandedCategoryIds(getPathIDsBySlug(tree, categorySlug));
  }, [tree, categorySlug]);

  const renderCategories = useCallback(
    (categoryList, isChild = false) => {
      const activeCategoryId = getPathIDsBySlug(tree, categorySlug)[0];
      const activeCategoryIndex = categoryList.findIndex((item) => item.id === activeCategoryId);
      const categoriesTotal = categoryList.length;
      let startIndex;
      let endIndex;

      if (activeCategoryIndex === 0 || activeCategoryIndex === -1) {
        startIndex = 0;
        endIndex = Math.min(countVisibleElements, categoriesTotal);
      } else if (activeCategoryIndex === categoriesTotal - 1) {
        startIndex = Math.max(0, categoriesTotal - countVisibleElements);
        endIndex = categoriesTotal;
      } else {
        startIndex = Math.max(0, activeCategoryIndex - 2);
        endIndex = Math.min(categoriesTotal, activeCategoryIndex + 3);
      }

      const elementsToRender = showAll || isChild ? categoryList : categoryList.slice(startIndex, endIndex);

      return elementsToRender.map((category) => {
        const isCurrentCategory = expandedCategoryIds.includes(category.id);

        return (
          <ul key={category.id + category.slug}>
            <li className={`${isCurrentCategory ? styles.active : ''} ${category.parent_category && isChild ? styles.subcategory : ''}`}>
              <div
                className={`${styles.listItemTitle} ${
                  categorySlug === category.slug ? styles.currentCategoryLink : ''
                }`}
              >
                <Link to={linkPath(category)} relative="route">
                  {category.title}
                </Link>
                {category.child.length > 0 && (
                  <button type="button" onClick={() => handleExpandedCategoryIds(category.slug)}>
                    <Arrow />
                  </button>
                )}
              </div>
              {category.child && expandedCategoryIds.includes(category.id) && renderCategories(category.child, true)}
            </li>
          </ul>
        );
      })
    },
    [categorySlug, countVisibleElements, expandedCategoryIds, handleExpandedCategoryIds, linkPath, showAll, tree],
  );

  return renderCategories(tree);
}

export default SideMenuItemsList;
