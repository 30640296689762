import axios from 'axios';
import { LOCAL_STORE_AUTH_KEY, LOCAL_STORE_REFRESH_KEY } from 'config';
import { getNewAccessToken } from 'helpers/getNewAccessToken';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(LOCAL_STORE_AUTH_KEY);

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest?.$retry) {
      originalRequest.$retry = true;

      const refreshToken = localStorage.getItem(LOCAL_STORE_REFRESH_KEY);

      const newAccessToken = await getNewAccessToken(refreshToken);

      if (newAccessToken) {
        localStorage.setItem(LOCAL_STORE_AUTH_KEY, newAccessToken);

        originalRequest.$retry = false;

        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
