import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { findPathByIdRecursive } from 'helpers/findPathByIdRecursive';
import useStore from 'hooks/useStore';
import Container from 'components/shared/Container';
import { routeLinks } from 'config/routeLinks';
import styles from './style.module.scss';

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const { dictionaries } = useStore('GlobalStore');
  const [paths, setPaths] = useState([]);

  const { '*': nestedPath } = useParams()
  const nestedPathArray = nestedPath.split('/').filter((part) => part !== '')

  const breadcrumbLinks = paths.reduce((acc, path) => {
    if (path?.slug) {
      const accumulatedPath = acc.length > 0
        ? `${acc[acc.length - 1].to}${path.slug}/`
        : `${routeLinks.products}${path.slug}/`;

      acc.push({
        to: accumulatedPath,
        title: path.title,
      });
    }
    return acc;
  }, []);

  const constructPaths = () => {
    let pathsAcc = [];

    nestedPathArray.forEach((path, index) => {
      if (index === 0) {
        const [catalogId, categoryId] = findPathByIdRecursive(dictionaries, path);

        if (catalogId?.id || categoryId?.id) {
          pathsAcc = [...pathsAcc, catalogId, categoryId];
        }
      } else {
        const [_, categoryId] = findPathByIdRecursive(dictionaries, path);

        if (categoryId?.id) {
          pathsAcc.push(categoryId);
        }
      }
    })

    setPaths(pathsAcc);
  };

  useEffect(() => {
    constructPaths()
  }, [pathname]);

  return (
    <Container>
      <ul className={styles.breadcrumbs}>
        <Link to="/">Каталог</Link>
        {breadcrumbLinks.slice(0, -1).map(({ to, title }) => (
          <Link key={to} to={to}>
            {title}
          </Link>
        ))}
      </ul>
    </Container>
  );
};

export default observer(Breadcrumbs);
