import React from 'react'
import InfoColumn from 'modules/Cart/InfoColumn'

export const currentOrderColumns = () => [
  {
    title: 'Информация',
    children: [
      {
        title: '',
        dataIndex: ['product', 'preview'],
        render: (image) => <img src={image || null} alt="product" />,
      },
      {
        title: '',
        render: ({ product }) => (
          <InfoColumn
            title={product?.title}
            link={product?.category?.url}
            article={product?.article_number}
            category={product?.category?.title}
          />
        ),
      },
    ],
  },
  {
    title: 'Цена',
    dataIndex: ['price'],
    render: (value = 1) => (
      <span>
        {value}
        <span>p</span>
      </span>
    ),
    align: 'center',
  },
  {
    title: 'Количество товаров',
    dataIndex: 'quantity',
    align: 'center',
  },
  {
    title: 'Сумма',
    dataIndex: ['amount'],
    render: (amount) => (
      <span>
        {amount}
        <span>p</span>
      </span>
    ),
    align: 'center',
  },
  {
    title: 'Объем, м3',
    dataIndex: 'volume',
    align: 'center',
  },
]
