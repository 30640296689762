import { useEffect } from 'react'
import Table from 'rc-table'
import { observer } from 'mobx-react'
import { useInView } from 'react-intersection-observer'
import useStore from 'hooks/useStore'
import RemoteControlScrollbar from 'components/shared/RemoteControlScrollbar'
import styles from './styles.module.scss'

const OrdersMainTable = ({ ordersList, columns }) => {
  const isEmpty = ordersList?.results?.length > 0

  const { getNextPageOrders } = useStore('UserStore')

  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      getNextPageOrders()
    }
  }, [getNextPageOrders, inView])

  return isEmpty ? (
    <>
      <RemoteControlScrollbar sourceTarget="myOrigScrollableArea" contentSelector="table" />
      <div id="myOrigScrollableArea" className={styles.tableWrapper}>
        <Table
          className={styles.table}
          columns={columns}
          data={ordersList?.results}
          rowKey={() => window.self.crypto.randomUUID()}
          emptyText="Данные отсутсвтуют"
        />
      </div>

      <div ref={ref} />
    </>
  ) : (
    <div>Нет данных</div>
  )
}

export default observer(OrdersMainTable)
