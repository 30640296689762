import { useId } from 'react';
import { Mousewheel, FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-round.svg';
import Image from 'components/shared/Image';
import useStore from 'hooks/useStore';
import styles from './styles.module.scss';

export const SlidePrevButton = () => {
  const swiper = useSwiper();

  return (
    <button
      onClick={() => swiper.slidePrev()}
      className={styles.arrowPrev}
      type="button"
      aria-label="Next Image"
    >
      <ArrowIcon />
    </button>
  );
};

export const SlideNextButton = () => {
  const swiper = useSwiper();

  return (
    <button
      onClick={() => swiper.slideNext()}
      className={styles.arrowNext}
      type="button"
      aria-label="Next Image"
    >
      <ArrowIcon />
    </button>
  );
};

const Gallery = ({ images }) => {
  const slidID = useId();
  const isMoreOne = images.length > 1;
  const { breakpoint } = useStore('GlobalStore');
  const isMobile = breakpoint === 'mobile';

  return (
    <Swiper
      className={styles.slider}
      modules={[Mousewheel, FreeMode, Pagination]}
      lazy="true"
      pagination={{
        clickable: true,
      }}
      spaceBetween={10}
      slidesPerView="auto"
      allowTouchMove={isMobile}
    >
      {images.map(({ image }) => (
        <SwiperSlide
          className={styles.menuItem}
          key={slidID + image || '_slide'}
        >
          <div className={styles.slideItem} key={image}>
            <Image className={styles.slideImage} src={image} alt="gallery image" />
          </div>
        </SwiperSlide>
      ))}

      {isMoreOne && <SlidePrevButton />}

      {isMoreOne && <SlideNextButton />}
    </Swiper>
  );
};

export default Gallery;
