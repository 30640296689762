import Checkbox from 'components/shared/Checkbox';
import { ReactComponent as Tresh } from 'assets/svg/tresh.svg';

import ProductCounterWrapper from 'components/ProductCounterWrapper';
import InfoColumn from './InfoColumn';
import CommentButton from './CommentButton';

export const columns = ({
  isAllRowsSelected,
  handleSelect,
  handleSelectAll,
  handleRemove,
  handleRemoveSelected,
  styles,
  isDesktop,
}) => [
  {
    title: (
      <div className={styles.tableHeaderButtonsWrapper}>
        <Checkbox
          id="all"
          label="Выбрать все"
          checked={isAllRowsSelected}
          onChange={handleSelectAll}
        />

        <button type="button" onClick={handleRemoveSelected}>
          <Tresh />
          Удалить выбранные
        </button>
      </div>
    ),
    children: [
      {
        title: '',
        dataIndex: 'selected',
        render: (_, record) => (
          <div className={styles.cbWrapper}>
            <Checkbox
              id={record.id}
              checked={record.selected}
              onChange={() => handleSelect(record)}
            />
          </div>
        ),
        align: 'center',
        width: 40,
        fixed: true,
      },
      {
        render: ({ product, quantity }) => (
          <InfoColumn
            className={styles.cardImage}
            productId={product?.id}
            preview={product?.preview}
            title={`${product?.title}${
              product.in_stock === quantity ? ' (ЗАКОНЧИЛСЯ)' : ''
            }`}
            link={product?.category?.url}
            article={product?.article_number}
            category={product?.category?.title}
          />
        ),
        title: '',
        width: isDesktop ? 'auto' : 343,
      },
    ],
  },
  {
    title: '',
    render: ({ id }) => <CommentButton id={id} />,
    align: 'center',
    width: 120,
  },
  {
    title: 'Скидка',
    dataIndex: ['discount'],
    render: (value) => {
      const isDiscount = value !== 0;

      return (
        <span
          className={`${styles.discount} ${
            isDiscount ? styles.colorGreen : styles.colorRed
          }`}
        >
          {value !== 0 ? `${value}%` : '—'}
        </span>
      );
    },
    align: 'center',
    width: 50,
  },
  {
    title: 'Ваша цена (с НДС)',
    dataIndex: ['product', 'price'],
    render: (value = 1) => (
      <span className={styles.priceCount}>
        {value}
        <span className={styles.currency}>p</span>
      </span>
    ),
    align: 'center',
    width: isDesktop ? 'auto' : 100,
  },
  {
    title: 'Количество, шт.',
    align: 'center',
    width: 110,
    render: (props) => {
      const { quantity, product, id } = props;

      return (
        <ProductCounterWrapper
          id={id}
          inStock={product.in_stock}
          productId={product.id}
          minQuantity={product.min_quantity}
          quantity={quantity}
          count={product.in_cart?.count}
          isCartAction
          isCartCounter
        />
      );
    },
  },
  {
    title: 'Сумма (с НДС)',
    render: ({ amount }) => (
      <span className={styles.priceCount}>
        {amount}
        <span className={styles.currency}>p</span>
      </span>
    ),
    align: 'center',
    width: isDesktop ? 'auto' : 100,
  },
  {
    title: 'Кол-во в уп.',
    dataIndex: ['product', 'package_quantity'],
    align: 'center',
    width: isDesktop ? 'auto' : 60,
  },
  {
    render: (_, record) => (
      <button type="button" onClick={() => handleRemove(record)}>
        <Tresh />
      </button>
    ),
    width: 20,
  },
];

export const sortOptions = [
  { label: 'По умолчанию', value: null },
  {
    value: 'price',
    label: 'Только дешевле',
  },
  {
    value: '-price',
    label: 'Только дороже',
  },
];
