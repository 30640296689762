import React from 'react';
import style from './style.module.scss';

const Footer = () => (
  <footer className={style.footer}>
    <p>© 1998-2022, СООО «ПП Полесье» (УНП 200652036). Все права защищены.</p>
  </footer>
);

export default Footer;
