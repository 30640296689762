import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import Container from 'components/shared/Container'
import { useSearchParams, useLocation } from 'react-router-dom'
import ProductList from 'modules/Products/ProductList'

import Filters from 'components/Filters'
import Title from 'components/shared/Title'
import SideMenu from 'components/SideMenu'
import styles from './styles.module.scss'

const listAvailableProductsRoute = ['new', 'popular', 'sale'];
const translateTitle = {
  new: 'Новые',
  popular: 'Популярные',
  sale: 'АКЦИИ',
}

const Product = () => {
  const {
    requestProducts,
    requestNextPage,
    products,
    isLoading,
    isNextPageLoading,
    sideMenuTree,
    setCurrentSearchParams,
    isNextPageAvailable,
    currentPage,
    totalPages,
  } = useStore('ProductsStore');
  const { breakpoint } = useStore('GlobalStore');
  const { user } = useStore('UserStore');
  const { prepay } = user;
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()

  const isDesktop = breakpoint === 'desktop'
  const currentRouteFromAvailable = listAvailableProductsRoute.find((el) => el === pathname.split('/')[2]);

  const handleSearchParams = useCallback(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    listAvailableProductsRoute.forEach((route) => {
      if (searchParams.has(route)) {
        updatedSearchParams.delete(route);
      }
    });

    if (currentRouteFromAvailable) {
      updatedSearchParams.set(currentRouteFromAvailable, 1);
    }

    setCurrentSearchParams(updatedSearchParams)
    setSearchParams(updatedSearchParams)
    requestProducts()
  }, [currentRouteFromAvailable, requestProducts, searchParams, setSearchParams, setCurrentSearchParams, prepay]);

  const handlePageClick = ({ selected }) => {
    requestProducts(selected);
  };

  const showMoreProducts = () => {
    requestNextPage();
  }

  useEffect(() => {
    handleSearchParams();
  }, [handleSearchParams])

  return (
    <Container>
      <Title>
        Товары из категории
        {' '}
        {translateTitle[currentRouteFromAvailable]}
      </Title>

      <div className={styles.wrapper}>
        <div className={styles.filtersWrapper}>
          <SideMenu tree={sideMenuTree} />
          {isDesktop ? <Filters brandsList={products.brands} /> : null}
        </div>

        <ProductList
          isLoadingProducts={isLoading}
          isNextPageLoading={isNextPageLoading}
          products={products}
          isNextPageAvailable={isNextPageAvailable}
          handlePageClick={handlePageClick}
          currentPage={currentPage}
          showMoreProducts={showMoreProducts}
          totalPages={totalPages}
        />
      </div>
    </Container>
  )
}

export default observer(Product)
