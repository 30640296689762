import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import IconLines from 'components/shared/IconLines';
import { routeLinks } from 'config/routeLinks';
import style from './style.module.scss';

const HeaderCatalogBtn = () => {
  const { catalogSlug, categorySlug } = useParams();

  const pathResolve = useMemo(() => (
    catalogSlug && categorySlug ? `${routeLinks.products + catalogSlug}/` : '/#catalog'
  ), [catalogSlug, categorySlug]);

  return (
    <Link
      to={pathResolve}
      className={`${style.btn} ${style.btnCatalog}`}
      target="_top"
      type="button"
    >
      <IconLines extraStyle={style.lines} />
      Каталог
    </Link>
  );
};

export default HeaderCatalogBtn;
