import Container from 'components/shared/Container';
import Title from 'components/shared/Title';
import ProfileMenu from 'components/ProfileMenu';

import styles from './styles.module.scss';

const ProfileTemplate = (props) => {
  const { children, title = 'Личный кабинет', extra } = props;

  return (
    <Container>
      <Title extra={extra}>
        {title}
      </Title>

      <div className={styles.wrapper}>
        <div className={styles.sidbar}>
          <ProfileMenu />
        </div>

        <div className={styles.content}>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default ProfileTemplate;
