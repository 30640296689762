import { Outlet, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import BaseTemplate from 'components/templates/BaseTemplate';
import { routes } from 'config';

const PrivateRoute = () => {
  const { isUserAuthenticated } = useStore('GlobalStore');

  if (isUserAuthenticated) {
    return (
      <BaseTemplate>
        <Outlet />
      </BaseTemplate>
    );
  }

  return <Navigate to={routes.login()} />;
};

export default observer(PrivateRoute);
