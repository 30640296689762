import * as stores from './index';

const createStores = (allStores) => Object.keys(allStores).reduce(
  (acum, storeKey) => {
    acum[storeKey] = new allStores[storeKey]();
    return acum;
  },
  {},
);

export default createStores(stores);
