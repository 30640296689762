import React, { useState, useCallback } from 'react';
import { cartlinesPartialUpdate } from 'services/api/v1/cartlines';
import useStore from 'hooks/useStore';
import debounce from 'utils/debounce';
import { ReactComponent as DangerSVG } from 'assets/svg/danger.svg';
import { ERROR_TOAST_CONFIG } from 'config';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';
import { AddPopconfirm } from './AddPopconfirm';

const ProductCounter = (props) => {
  const {
    id,
    productId,
    inStock,
    minQuantity = 1,
    isCartAction,
    isCartCounter = false,
    value,
    setValue,
  } = props;

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const { updateProductValue, setCartSum } = useStore('CartStore');
  const isLimitReached = Number(value) === inStock;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCartLine = useCallback(
    debounce(async (val) => {
      if (val !== '0') {
        try {
          const { data } = await cartlinesPartialUpdate(id, {
            product_id: productId,
            quantity: val,
          });

          if (isCartAction) {
            updateProductValue(data);
          }

          if (!isCartAction) {
            setCartSum(data.total_sum);
          }

          return data;
        } catch (error) {
          toast('Что-то с обновлением данных', ERROR_TOAST_CONFIG);
        }
      }

      return {};
    }, 350),
    [id, isCartAction, productId, updateProductValue],
  );

  const handleIncrement = useCallback(() => {
    const newValue = parseInt(value, 10) + Number(minQuantity);

    if (newValue && newValue <= inStock) {
      updateCartLine(newValue);
      setValue(String(newValue));
    }
  }, [value, minQuantity, inStock, updateCartLine, setValue]);

  const handleDecrement = useCallback(() => {
    const newValue = parseInt(value, 10) - Number(minQuantity);

    if (newValue >= 0 && newValue) {
      updateCartLine(newValue);
      setValue(String(newValue));
    }
  }, [value, minQuantity, updateCartLine, setValue]);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;

      if (newValue === '') {
        setValue('');
        return;
      }

      let parsedValue = null;

      if (newValue.match(/^\d+$/)) {
        parsedValue = parseInt(newValue, 10);
      }

      if (parsedValue !== null) {
        parsedValue = Math.min(parsedValue, inStock);
        updateCartLine(parsedValue);
        setValue(parsedValue);
      }
    },
    [inStock, updateCartLine, setValue],
  );

  const handleBlur = useCallback(() => {
    if (value === '' || parseInt(value, 10) === 0) {
      setValue('1');
      updateCartLine(minQuantity);
    }
  }, [value, minQuantity, updateCartLine, setValue]);

  const addProduct = () => {
    if (minQuantity > 1) {
      setIsOpenPopup(true);
    } else {
      handleIncrement();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <button
          type="button"
          className={styles.minus}
          onClick={handleDecrement}
        >
          -
        </button>

        <input
          className={styles.inputCounter}
          name="input_counter"
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={minQuantity > 1}
        />

        <button
          type="button"
          disabled={isLimitReached}
          className={styles.plus}
          onClick={() => (isCartCounter ? handleIncrement() : addProduct())}
        >
          +
        </button>

        <AddPopconfirm
          count={minQuantity}
          open={isOpenPopup}
          setOpen={setIsOpenPopup}
          action={handleIncrement}
        />
      </div>

      {isCartCounter && isLimitReached && (
        <div className={styles.errorMessage}>
          <p>
            <DangerSVG />
            {`В данный момент ${inStock} шт.`}
            <br />
            максимально доступное кол-во
          </p>
        </div>
      )}
    </>
  );
};

export default ProductCounter;
