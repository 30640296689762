import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useStoreBreakpoint from 'hooks/useStoreBreakpoint';
import HeaderMenu from 'components/HeaderMenu';
import HeaderSearch from 'components/HeaderSearch';
import HeaderCatalogBtn from 'components/HeaderCatalogBtn';
import UserMenu from 'components/UserMenu';
import ManagerCard from 'components/manager/ManagerCard';
import Container from 'components/shared/Container';
import DiscountButton from 'components/DiscountButton';
import logoLg from 'assets/logo-lg.png';
import style from './style.module.scss';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const prevScrollPos = useRef(0);

  const headerTopHeight = useStoreBreakpoint();

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = document.documentElement;

      if (prevScrollPos.current > headerTopHeight && scrollTop > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      prevScrollPos.current = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerTopHeight]);

  return (
    <div className={`${style.shadow}`} id="headerWrapper">
      <Container>
        <header
          className={style.header}
          style={{ paddingTop: isSticky ? headerTopHeight : 0 }}
        >
          <div className={`${isSticky ? style.sticky : ''}`}>
            <div className={style.headerTop}>
              <div className={`${style.headerLogo}`}>
                <Link to="/">
                  <img src={logoLg} alt="Полесье логотип" />
                </Link>
              </div>

              <HeaderCatalogBtn />

              <DiscountButton isSticky={isSticky} />

              <HeaderSearch />

              <UserMenu />
            </div>
          </div>

          <div className={style.headerNav}>
            <HeaderMenu />

            <ManagerCard />
          </div>
        </header>
      </Container>
    </div>
  );
};

export default Header;
