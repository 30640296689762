import { makeAutoObservable } from 'mobx'
import { getProduct } from 'services/api/v1/products'
import { toast } from 'react-toastify'
import { ERROR_TOAST_CONFIG } from 'config/index'

export default class ProductStore {
  isLoading = false
  data = {}

  constructor() {
    makeAutoObservable(this)
  }

  requestProduct = async (params) => {
    this.data = {}

    if (!this.isLoading) {
      try {
        this.isLoading = true

        const { data } = await getProduct(params)

        this.data = data
      } catch (error) {
        toast(error.message, ERROR_TOAST_CONFIG)
      } finally {
        this.isLoading = false
      }
    }
  }
}
