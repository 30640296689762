export const options = [
  { label: 'По умолчанию', value: null },
  {
    value: 'price',
    label: 'Только дешевле',
  },
  {
    value: '-price',
    label: 'Только дороже',
  },
  {
    value: '-new',
    label: 'Только новые',
  },
  {
    value: 'new',
    label: 'Только старые',
  },
  {
    value: '-popular',
    label: 'Только популярные',
  },
  {
    value: 'popular',
    label: 'Только не популярные',
  },
  {
    value: '-sale',
    label: 'Только по акции',
  },
  {
    value: 'sale',
    label: 'Только без акции',
  },
];
