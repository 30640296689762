import { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore';
import { excludeSearchParams } from 'helpers/excludeSearchParams';
import Checkbox from 'components/shared/Checkbox';
import InputRange from 'components/shared/InputRange';
import { ReactComponent as Closer } from 'assets/svg/closer.svg'
import styles from './style.module.scss';

const DEFAULT_PRICE_MIN = 0;
const DEFAULT_PRICE_MAX = 1000;

const Filters = ({ category, products = [], handleOpenModal }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { categorySlug } = useParams()
  const { setCategorySlug, categoryBrands: categorysBrands, categoryFilters } = useStore('GlobalStore');

  const currentCategorySlug = category || categorySlug;

  const categoryBrands = products?.brands?.length > 0 ? products.brands : categorysBrands;
  const initialPriceFrom = searchParams.get('price__gte') || DEFAULT_PRICE_MIN;
  const initialPriceTo = searchParams.get('price__lte') || DEFAULT_PRICE_MAX;

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [priceRange, setPriceRange] = useState([
    initialPriceFrom,
    initialPriceTo,
  ]);

  const handleInitializeLocalState = useCallback(() => {
    setCategorySlug(currentCategorySlug);

    const initialSelectedBrands = Array.from(searchParams)
      .filter(([key]) => key === 'brand')
      .map(([, value]) => Number(value));
    setSelectedBrands(initialSelectedBrands);

    const initialSelectedFilters = Array.from(searchParams)
      .filter(([key]) => key.startsWith('filter__'))
      .flatMap(([key, value]) => value.split(',').map((val) => ({
        filterId: Number(key.split('__')[1]),
        valueId: Number(val),
      })));
    setSelectedFilters(initialSelectedFilters);

    setPriceRange([initialPriceFrom, initialPriceTo]);
  }, [currentCategorySlug, initialPriceFrom, initialPriceTo, searchParams, setCategorySlug]);

  useEffect(() => {
    handleInitializeLocalState();
  }, [handleInitializeLocalState]);

  const handleBrandQuery = useCallback((brandId) => {
    setSelectedBrands((prev) => {
      if (prev.includes(brandId)) {
        return prev.filter((id) => id !== brandId);
      }
      return [...prev, brandId];
    });
  }, []);

  const handleFilterQuery = useCallback((newFilter) => {
    setSelectedFilters((prev) => {
      const isActive = prev.some((filter) => filter.valueId === newFilter.valueId);

      if (isActive) {
        return prev.filter((element) => element.valueId !== newFilter.valueId);
      }
      return [...prev, newFilter];
    });
  }, []);

  const onPriceRangeChange = ([price__gte, price__lte]) => {
    setPriceRange([price__gte, price__lte]);
  };

  const applyFilters = () => {
    const excludedFields = ['brand', 'price__gte', 'price__lte'];
    const tempParams = new URLSearchParams(excludeSearchParams(searchParams, excludedFields));

    const newParams = new URLSearchParams();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of tempParams) {
      if (!key.startsWith('filter__')) {
        newParams.append(key, value);
      }
    }

    selectedBrands.forEach((brandId) => {
      newParams.append('brand', brandId);
    });

    const groupedFilters = selectedFilters.reduce((acc, filter) => {
      const { filterId, valueId } = filter;
      if (!acc[filterId]) {
        acc[filterId] = [];
      }
      acc[filterId].push(valueId);
      return acc;
    }, {});

    Object.entries(groupedFilters).forEach(([filterId, valueIds]) => {
      newParams.append(`filter__${filterId}`, valueIds.join(','));
    });

    if (priceRange[0] !== null) {
      newParams.append('price__gte', priceRange[0]);
    }
    if (priceRange[1] !== null) {
      newParams.append('price__lte', priceRange[1]);
    }

    setSearchParams(newParams);

    if (handleOpenModal) {
      handleOpenModal();
    }
  };

  const clearFilters = () => {
    setSelectedBrands([]);
    setSelectedFilters([]);
    setPriceRange([DEFAULT_PRICE_MIN, DEFAULT_PRICE_MAX]);

    const excludedFields = ['brand', 'price__gte', 'price__lte'];
    const tempParams = new URLSearchParams(excludeSearchParams(searchParams, excludedFields));

    const clearedParams = new URLSearchParams();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of tempParams) {
      if (!key.startsWith('filter__')) {
        clearedParams.append(key, value);
      }
    }

    setSearchParams(clearedParams);

    if (handleOpenModal) {
      handleOpenModal();
    }
  };

  return (
    <>
      {
                categoryBrands?.length ? (
                  <div
                    className={`${styles.container} ${styles.brands}`}
                  >
                    <div className={styles.filtersTitle}>
                      Бренды
                    </div>

                    {
                            categoryBrands?.map((brand) => {
                              const isChecked = selectedBrands.includes(brand.id);

                              return (
                                <Checkbox
                                  key={`checkbox_${brand.slug}_${brand.id}`}
                                  name={brand.slug}
                                  label={brand.title}
                                  id={brand.id}
                                  checked={isChecked}
                                  onChange={() => handleBrandQuery(brand.id)}
                                />
                              );
                            })
                        }
                  </div>
                ) : null
            }
      {
                categoryFilters?.length ? (
                  categoryFilters.map((categoryFilter) => (
                    <div
                      key={`categoryFilter_${categoryFilter.id}_${categoryFilter.value}`}
                      className={`${styles.container} ${styles.brands}`}
                    >
                      <div className={styles.filtersTitle}>
                        {categoryFilter.title}
                      </div>

                      {
                            categoryFilter.values?.map((categoryFilterValue) => {
                              const isChecked = selectedFilters.some((filter) => filter.valueId === categoryFilterValue.id);

                              return (
                                <Checkbox
                                  key={`checkbox_${categoryFilterValue.value}_${categoryFilterValue.id}`}
                                  label={categoryFilterValue.value}
                                  id={categoryFilterValue.id}
                                  checked={isChecked}
                                  onChange={() => handleFilterQuery({ filterId: categoryFilter.id, valueId: categoryFilterValue.id })}
                                />
                              );
                            })
                          }
                    </div>
                  ))
                )
                  : null
            }

      <div className={styles.container}>
        <div className={styles.filtersTitle}>
          Цена, р.
        </div>

        <InputRange
          defaultValue={[DEFAULT_PRICE_MIN, DEFAULT_PRICE_MAX]}
          priceValue={priceRange}
          onPriceRangeChange={onPriceRangeChange}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <button type="button" className={styles.applyBtn} onClick={applyFilters}>
          Применить
        </button>
        <button
          type="button"
          className={styles.clearBtn}
          onClick={clearFilters}
        >
          <Closer />
        </button>
      </div>

    </>
  );
};

export default observer(Filters);
