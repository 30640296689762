import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { ERROR_TOAST_CONFIG } from 'config/index';
import { multiCatalogsList } from 'services/api/v1/catalogs';

export default class MultiCategoriesStore {
  isLoading = false;
  data = [];

  constructor() {
    makeAutoObservable(this);
  }

  requestCategories = async (id) => {
    this.data = [];

    if (!this.isLoading) {
      try {
        this.isLoading = true;

        const { data } = await multiCatalogsList({ id });

        this.data = data.blocks;
      } catch (error) {
        toast(error.message, ERROR_TOAST_CONFIG);
      } finally {
        this.isLoading = false;
      }
    }
  };
}
