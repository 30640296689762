import styles from './styles.module.scss';

export const AddPopconfirm = ({ children, count, open, setOpen, action }) => {
  const cancel = () => {
    setOpen(false)
  }

  const next = async () => {
    try {
      await action();
    } catch (error) {
      console.log(error);
    }

    cancel()
  }

  return (
    <>
      {children}

      {
        open && (
          <div className={styles.popconfirm}>
            <h4 className={styles.popconfirmTitle}>
              <b>
                {`Добавить в корзину ${count} штук`}
              </b>
              <span>
                {`(отгружается кратно ${count})`}
              </span>
            </h4>

            <div className={styles.popconfirmControls}>
              <button className={styles.btnAction} onClick={() => next()} type="button">Да</button>
              <button className={styles.btnAction} onClick={() => cancel()} type="button">Нет</button>
            </div>
          </div>
        )
      }
    </>
  )
}

export default AddPopconfirm;
