import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styles from './styles.module.scss';

const InputRange = ({
  defaultValue,
  priceValue,
  onPriceRangeChange,
}) => {
  const handleRangeChange = ([valueMin, valueMax]) => {
    onPriceRangeChange([valueMin, valueMax]);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const newValue = Number(value);

    if (event.target.id === 'input-min') {
      onPriceRangeChange([newValue, priceValue[1]]);
    } else if (event.target.id === 'input-max') {
      onPriceRangeChange([priceValue[0], newValue]);
    }
  };

  return (
    <div className={styles.customInput}>
      <div className={styles.rangeInputsContainer}>
        <input
          className={styles.inputRange}
          type="number"
          onChange={handleChange}
          value={priceValue[0]}
          id="input-min"
        />
        <span className={styles.separator} />

        <input
          className={styles.inputRange}
          type="number"
          onChange={handleChange}
          value={priceValue[1]}
          id="input-max"
        />
      </div>

      <Slider
        range
        value={priceValue}
        min={defaultValue[0]}
        max={defaultValue[1]}
        allowCross={false}
        onChange={handleRangeChange}
      />
    </div>
  );
};

export default InputRange;
