import instance from 'services/request';

export const getFavoritesItems = (params) => instance.get('/v1/users/likes/', params);

export const getProfile = (params) => instance.get('/v1/users/profile/', params);

export const postProfile = (params) => instance.patch('/v1/users/profile/', params, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const changePassword = (params) => instance.post('/v1/auth/change_password/', params);

export const postSiteFeedback = (params) => instance.post('/v1/reviews/', params, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
