import { useState } from 'react';
import styles from './styles.module.scss';

const Textarea = ({ initValue, name, id, register, required }) => {
  const [val, setVal] = useState(initValue);

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  return (
    <div className={styles.textareaWrapper}>
      <label htmlFor={id}>
        Дополнительная информация:
        <textarea
          id={id}
          name={name}
          rows={6}
          maxLength={500}
          onChange={handleChange}
          value={val}
          {...register(name, { required })}
        />
      </label>
    </div>
  );
};

export default Textarea;
