import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

const RemoteControlScrollbar = ({ sourceTarget, contentSelector }) => {
  const [contentWidth, setContentWidth] = useState(0);
  const targetRef = useRef(null);
  const thisRef = useRef(null);

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    const sourceRef = event.target === thisRef.current ? targetRef : thisRef;

    sourceRef.current.scroll({
      top: 0,
      left: scrollLeft,
      behavior: 'instant',
    });
  };

  useEffect(() => {
    const scrollableElem = document.getElementById(sourceTarget);

    if (contentSelector) {
      const elementContentWidth = scrollableElem.querySelector(contentSelector)?.clientWidth || '100%';
      setContentWidth(elementContentWidth);
    }

    targetRef.current = scrollableElem;
  }, [contentSelector, sourceTarget]);

  useEffect(() => {
    thisRef.current?.addEventListener('scroll', handleScroll);
    targetRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      thisRef.current?.removeEventListener('scroll', handleScroll);
      targetRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={thisRef} className={styles.scrollbarContainer}>
      <div className={styles.contentEmitter} style={{ width: contentWidth }} />
    </div>
  );
};

export default RemoteControlScrollbar;
