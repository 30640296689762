const findPath = (array, id) => {
  if (!array) return '';
  // Create a map for quick lookup by id
  const map = new Map();
  array.forEach((item) => {
    map.set(item.id, item);
  });

  // Function to build the path from a node to the root
  const buildPath = (node) => {
    const path = [];

    while (node.parent_category !== null) {
      path.unshift(node.slug);
      node = map.get(node.parent_category);
    }
    path.unshift(node.slug);
    return path.slice(0, -1).join('/');
  }

  // Find the starting node by the given id
  const startNode = map.get(id);
  if (!startNode) {
    return [];
  }

  // Build and return the path
  return buildPath(startNode);
}

// Helper function to find category slug
export const handleFindCategorySlug = (originalCategories, targetId) => {
  const getItem = originalCategories?.find((element) => element.categories.some((category) => category.id === targetId));

  findPath(getItem?.categories, targetId);

  return `${getItem?.slug}/${findPath(getItem?.categories, targetId)}`;
};

// Helper function to get query parameters from category
export const getQueryParamString = (category) => {
  if (!category || !category.searchParams) {
    return null;
  }

  const { searchParams } = category;

  if (searchParams.has('new')) {
    return '?new=1'
  }

  if (searchParams.has('popular')) {
    return '?popular=1'
  }

  if (searchParams.has('sale')) {
    return '?sale=1'
  }

  return '';
};

export const getPathIDsBySlug = (data, slug) => {
  let result = []

  function searchObjects(arr, path) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].slug === slug) {
        result = path.concat(arr[i].id);
      }
      if (arr[i].child.length) {
        searchObjects(arr[i].child, path.concat(arr[i].id));
      }
    }
  }

  searchObjects(data, []);
  return result;
};
