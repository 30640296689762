import { useEffect } from 'react'
import { observer } from 'mobx-react'
import useStore from 'hooks/useStore'
import { LOCAL_STORE_AUTH_KEY, LOCAL_STORE_REFRESH_KEY } from 'config'
import useBreakpoint from 'use-breakpoint'

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1200 }

const CriticalDataLoader = ({ children }) => {
  const {
    isUserAuthenticated,
    updateUserAuthentication,
    getDictionaries,
    getBaseData,
    setBreakpoint,
    setIsLoadingCriticalData,
    baseInfo,
  } = useStore('GlobalStore')

  const { setCartCount, setCartSum } = useStore('CartStore')

  const { getUser, setCount } = useStore('UserStore')

  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  useEffect(() => {
    let init = true

    const validDataAuth = async () => {
      try {
        await getUser()

        await getBaseData()

        await getDictionaries()
      } catch (error) {
        updateUserAuthentication(false)

        localStorage.removeItem(LOCAL_STORE_AUTH_KEY)

        localStorage.removeItem(LOCAL_STORE_REFRESH_KEY)
      } finally {
        setIsLoadingCriticalData(true)
      }
    }

    if (isUserAuthenticated && init) {
      validDataAuth()
    }

    return () => {
      init = false
    }
  }, [
    getBaseData,
    getUser,
    getDictionaries,
    updateUserAuthentication,
    setIsLoadingCriticalData,
    isUserAuthenticated,
  ])

  useEffect(() => {
    let init = true

    if (init) {
      const { likes, cart } = baseInfo

      setBreakpoint(breakpoint)

      setCount(likes)

      setCartCount(cart?.total_count)

      setCartSum(cart?.total_sum)
    }

    return () => {
      init = false
    }
  }, [baseInfo, setCount, setCartCount, setCartSum, setBreakpoint, breakpoint])

  return children
}

export default observer(CriticalDataLoader)
