import instance from 'services/request';

export const catalogsList = (params) => instance.get('/v1/catalogs/', params);

export const catalogsCategoriesList = ({ params, catalogId }) => instance.get(`/v1/catalogs/${catalogId}/categories/`, params);

export const catalogsCategoriesProductsList = ({ params, catalogId, categoryId, cancelToken }) => instance.get(`/v1/catalogs/${catalogId}/categories/${categoryId}/products/`, { params, cancelToken });

export const catalogsCategoriesProductsUpdate = (params) => instance.put('/v1/catalogs/{catalog_id}/categories/{category_id}/products/{id}/', params);

export const catalogsCategoriesProductsPartialUpdate = ({ url, id }, params) => instance.patch(`/v1${url}products/${id}/`, params);

export const catalogsCategoriesRead = ({ catalogId, categoryId }) => instance.get(`/v1/catalogs/${catalogId}/categories/${categoryId}/`);

export const catalogsRead = (params) => instance.get('/v1/catalogs/{id}/', params);

export const multiCatalogsList = ({ id, ...params }) => instance.get(`/v1/pages/${id}/`, params);
